<script lang="ts">
  import Nut from '@/assets/svg/Nut.svelte';
  import { locale, locales, t } from '@/locales/i18n';

  export let navbarPlacement = false;
</script>

<div
  class="{!navbarPlacement
    ? 'px-10'
    : 'px-2'} flex w-full flex-row items-center justify-start gap-4"
>
  {#if !navbarPlacement}
    <Nut />
    <label
      for="language"
      class=" whitespace-nowrap font-semibold text-dark-grey"
      >{$t('locale.select')}</label
    >
  {/if}
  <select
    class="px-1 font-normal hover:cursor-pointer"
    bind:value={$locale}
    data-cy={navbarPlacement
      ? 'language-selector'
      : 'language-selector-sidebar'}
  >
    {#each locales as l}
      <option value={l}>{l === 'en' ? 'English' : 'Español'}</option>
    {/each}
  </select>
</div>
