<script lang="ts">
  import type { ComponentProps } from 'svelte';
  import { navigate } from 'svelte-navigator';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import { UserRole } from '@/interface/IUser';
  import { logout } from '@/lib/services/together/user.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import SidebarMenuButton from '../buttons-group/buttons/SidebarMenuButton.svelte';
  import WelcomeUserCard from '../cards/WelcomeUserCard.svelte';
  import ExperienceIcon from '../icons/ExperienceIcon.svelte';
  import LogoutIcon from '../icons/LogoutIcon.svelte';
  import PersonIcon from '../icons/PersonIcon.svelte';
  import UsersIcon from '../icons/UsersIcon.svelte';
  import LocaleSelector from '../locale-selector/LocaleSelector.svelte';

  let isHost = $userStore?.role === UserRole.HOST;
  let isUser = $userStore?.role === UserRole.USER;
  let isAdmin =
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.SUPER_ADMIN;

  let dashboardButtons: (ComponentProps<SidebarMenuButton> & {
    icon: typeof PersonIcon | typeof ExperienceIcon | typeof UsersIcon;
    url: string;
  })[] = [
    {
      text: $t('dashboard.sidebar.users'),
      icon: PersonIcon,
      url: '/dashboard/users',
      selected: isAdmin,
    },
    {
      text: $t('dashboard.sidebar.experiences'),
      icon: ExperienceIcon,
      url: '/dashboard/experiences',
      selected: isHost,
    },
    {
      text: $t('dashboard.sidebar.sessions'),
      icon: UsersIcon,
      url: '/dashboard/sessions',
      selected: isUser,
    },
  ];

  $: dashboardButtons = [
    {
      text: $t('dashboard.sidebar.users'),
      icon: PersonIcon,
      url: '/dashboard/users',
      selected: isAdmin,
    },
    {
      text: $t('dashboard.sidebar.experiences'),
      icon: ExperienceIcon,
      url: '/dashboard/experiences',
      selected: isHost,
    },
    {
      text: $t('dashboard.sidebar.sessions'),
      icon: UsersIcon,
      url: '/dashboard/sessions',
      selected: isUser,
    },
  ];

  $: dashboardButtons = isUser
    ? dashboardButtons.filter(
        (button) => button.text === $t('dashboard.sidebar.sessions'),
      )
    : isHost
      ? dashboardButtons.filter(
          (button) => button.text !== $t('dashboard.sidebar.users'),
        )
      : dashboardButtons;

  $: $locale,
    (dashboardButtons = dashboardButtons.map((button) => ({
      ...button,
      selected: location.pathname.includes(button.url),
    })));

  const handleNavigation = (url: string) => {
    navigate(url);
    dashboardButtons = dashboardButtons.map((button) => ({
      ...button,
      selected: location.pathname.includes(button.url),
    }));
  };

  const handleLogout = async (): Promise<void> => {
    await logout();
    navigate('/');
  };
</script>

<div class="flex w-80 flex-col justify-between border-r border-light-grey py-4">
  <div class="flex w-full flex-col items-center justify-center gap-10 pt-4">
    <TogetherLogo className="h-16" />
    <WelcomeUserCard />
    <div class="flex w-full flex-col items-start justify-center">
      {#each dashboardButtons as button}
        <SidebarMenuButton
          text={button.text}
          selected={button.selected}
          on:click={() => handleNavigation(button.url)}
        >
          <svelte:component this={button.icon} />
        </SidebarMenuButton>
      {/each}
    </div>
  </div>
  <div class="flex w-full flex-col gap-4">
    <LocaleSelector navbarPlacement={false} />
    <div
      class="flex w-full flex-row items-center gap-4 px-10 font-bold text-dark-grey hover:cursor-pointer hover:text-primary"
    >
      <LogoutIcon style="h-5 w-5 rotate-90" />
      <span class="font-semibold" on:keypress on:click={handleLogout}
        >{$t('dashboard.sidebar.logout')}</span
      >
    </div>
  </div>
</div>
