<script lang="ts">
  import type { ILuckyCardsResponse } from 'src/lib/components/mini-games/lucky-cards/@types/ILuckyCardsResponse';
  import type { CardType } from 'src/lib/components/mini-games/lucky-cards/@types/LuckyCards';
  import { onMount } from 'svelte';
  import Card from '@/lib/components/mini-games/lucky-cards/Card.svelte';
  import CardsConfig from '@/lib/components/mini-games/lucky-cards/CardsConfig.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import Api from '@/lib/services/axios/api-axios';
  import { ToastNotification } from '@/lib/services/toast';
  import { MiniGamesEnum } from '@/routes/mini-games/@types/MiniGames.enum';

  export let id: string | undefined;
  let cards: CardType[] = [];
  let error = '';

  function handleUpdateCards(event: CustomEvent<any>) {
    error = '';
    cards = event?.detail;
  }

  function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function handlePrepareCards() {
    if (!cards.length) {
      ToastNotification.error('There are no cards to prepare!', {
        duration: 2000,
      });
      return;
    }
    cards = shuffleArray([...cards]).map((card) => ({
      ...card,
      flipped: false,
    }));
    ToastNotification.success('Cards shuffled successfully!', {
      duration: 2000,
    });
  }

  function handleRevealAllCards() {
    if (!cards.length) {
      ToastNotification.error('There are no cards to reveal!', {
        duration: 2000,
      });
      return;
    }
    cards = cards.map((card) => ({
      ...card,
      flipped: true,
    }));

    ToastNotification.success('Cards revealed successfully!', {
      duration: 2000,
    });
  }

  function handleRemoveAllCards() {
    if (!cards.length) {
      ToastNotification.error('There are no cards to remove!', {
        duration: 2000,
      });
      return;
    }
    cards = [];
    ToastNotification.success('Cards removed successfully!', {
      duration: 2000,
    });
  }

  let showSidebar = false;

  function toggleSidebar() {
    showSidebar = !showSidebar;
  }

  async function loadCards() {
    try {
      const response = await Api.get<ILuckyCardsResponse>(
        `/mini-game/${id}?gameName=${MiniGamesEnum.LUCKY_CARDS}`,
      );
      let cardsContent = JSON.parse(response.data.content);
      cardsContent = cardsContent.map((card: CardType) => ({
        ...card,
        flipped: false,
        suit: 'classic',
      }));

      cards = [...cardsContent];
      return cards;
    } catch {
      error =
        "Error loading cards from API. Maybe they don't exist or the link not corresponds to a valid configuration for this game.";

      return [];
    }
  }

  onMount(async () => {
    cards = await loadCards();
  });
</script>

<Page>
  <div
    class="flex flex-row items-center justify-between"
    data-cy="lucky-cards-section"
  >
    <h1 class="my-5 pe-3 text-3xl" data-cy="lucky-cards-title">Lucky Cards</h1>
    <button
      class="h-10 w-16 rounded-md bg-indigo-500 text-sm font-bold text-white transition duration-200 ease-in hover:bg-indigo-700"
      on:click={toggleSidebar}
      data-cy="lucky-cards-toggle-sidebar-btn"
      >{showSidebar ? 'Hide' : 'Show'} Sidebar</button
    >
  </div>
  <div class="flex flex-row items-center justify-center gap-[20px]">
    <div class="grid grid-cols-8 items-center justify-center gap-2">
      {#each cards as card, index (index)}
        <Card
          id={index}
          name={card.name}
          content={card.content}
          showCardBack={card.flipped}
          suit={card.suit}
        />
      {/each}
    </div>
    {#if !cards.length && !error}
      <p class="text-center">
        No cards added yet. Start by adding some in the sidebar configuration
        panel
      </p>
    {/if}
    <div />
    {#if error && !cards.length}
      <div class="flex flex-col items-center justify-center">
        <p>{error}</p>
      </div>
    {/if}

    <CardsConfig
      cards={cards}
      showSidebar={showSidebar}
      on:updateCards={handleUpdateCards}
      on:prepareCards={handlePrepareCards}
      on:revealAllCards={handleRevealAllCards}
      on:removeAllCards={handleRemoveAllCards}
    />
  </div>
</Page>
