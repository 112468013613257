<script lang="ts">
  import type { IUser } from '@/interface/IUser';

  export let users: IUser[];
  export let selectedUsers: Set<IUser>;
  export let onSelect: (user: IUser) => void;
</script>

<ul
  class="flex h-64 flex-col gap-2 overflow-auto rounded-lg border border-light-grey px-4 py-2"
>
  {#each users as user}
    <li class="flex w-full flex-row items-center">
      <label class="flex w-[30rem] items-center gap-2">
        <input
          data-cy="invite-user-checkbox-{user.id}"
          class="accent-primary"
          type="checkbox"
          checked={selectedUsers.has(user)}
          on:change={() => onSelect(user)}
        />
        <span class="font-semibold">{user.name} {user.lastName}</span>
      </label>
      <div class="flex w-full flex-row items-center justify-between gap-4">
        <span>{user.email}</span>
        <span>{user.role}</span>
      </div>
    </li>
  {/each}
</ul>
