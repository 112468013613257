<script lang="ts">
  import DiceFaces from './DiceFaces.svelte';

  export let diceFaceColor = 'bg-gray-700';
  export let diceBackgroundColor = 'bg-white';
  export const rollDice = (): Promise<number> => handleRollDice();

  let currentNumber = 1;
  let interval: any;

  const generateRandomNumber = () => {
    currentNumber = Math.floor(Math.random() * 6) + 1;
  };

  const handleRollDice = () => {
    clearInterval(interval);

    let count = 0;
    const maxIntervalCount = 40;

    return new Promise<number>((resolve) => {
      interval = setInterval(() => {
        count++;
        generateRandomNumber();
        if (count >= maxIntervalCount) {
          clearInterval(interval);
          resolve(currentNumber);
        }
      }, 50);
    });
  };
</script>

<div class="flex flex-wrap justify-center text-white">
  <div
    class={`h-36 w-36 ${diceBackgroundColor} flex transform justify-between rounded-xl border p-4 shadow-xl shadow-gray-500`}
    data-cy="dice"
  >
    <DiceFaces diceFace={currentNumber} diceFaceColor={diceFaceColor} />
  </div>
</div>
