<script lang="ts">
  import Page from '@/lib/components/page/Page.svelte';
</script>

<Page>
  <div class="mx-auto mb-8 max-w-2xl p-4 md:p-6 lg:p-8">
    <h1 class="mb-4 text-4xl font-bold text-primary" data-cy="terms-page-title">
      Terms and Conditions for Using the "Together" Slack Application
    </h1>
    <p class="text-md mb-2">Last Updated: 2024-09-17</p>
    <p class="mb-10 text-lg">
      Welcome to Together. These Terms and Conditions ("Terms") govern the use
      of the Application, which operates within the Slack platform to facilitate
      communication and collaboration among teams. By installing and using the
      Application, you agree to these Terms.
    </p>

    <h2 class="mb-2 text-2xl font-bold">1. Acceptance of the Terms</h2>
    <p class="mb-10 text-lg">
      By accessing and using the Application, you confirm that you have the
      authority to accept these Terms on behalf of the organization or team you
      represent, and you agree to comply with them.
    </p>

    <h2 class="mb-2 text-2xl font-bold">2. Service Description</h2>
    <p class="mb-4 text-lg">
      The Application allows Together users to have a dedicated Slack
      communication channel for participants in each experience, aiming to
      facilitate organization and encourage communication among them.
      Functionalities may include, but are not limited to:
    </p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li class="mb-2">Sending automated messages and reminders.</li>
      <li class="mb-2">Messages may include links to other applications.</li>
      <li class="mb-2">Messages may include attachments.</li>
    </ul>

    <h2 class="mb-2 text-2xl font-bold">3. Use of the Application</h2>
    <p class="mb-4 text-lg">
      By using the Application, you agree to the following:
    </p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li class="mb-2">
        You will not use the Application to send abusive, illegal messages, or
        messages that violate Slack policies.
      </li>
      <li class="mb-2">
        You will not interfere with the operation of the Application or attempt
        to disable or circumvent any of its security measures.
      </li>
      <li class="mb-2">
        You will respect the privacy and property rights of other users and
        third parties.
      </li>
      <li class="mb-2">
        The Application will not be used to send spam or make unsolicited
        communications to users not involved in the projects.
      </li>
    </ul>

    <h2 class="mb-2 text-2xl font-bold">4. Privacy and Security</h2>
    <p class="mb-10 text-lg">
      Together respects your privacy. All information collected will be managed
      in accordance with our <a
        href="/privacy"
        class="text-blue-600 hover:text-blue-800">Privacy Policy</a
      >.
    </p>

    <h2 class="mb-2 text-2xl font-bold">5. User Responsibilities</h2>
    <p class="mb-10 text-lg">
      You are responsible for ensuring the security of your Slack account and
      for actions taken through the Application using your account. You must
      comply with applicable local, national, and international laws when using
      the Application.
    </p>

    <h2 class="mb-2 text-2xl font-bold">6. Intellectual Property</h2>
    <p class="mb-10 text-lg">
      All rights, titles, and interests in the Application, including any
      associated content, software, and materials, are owned by Together or its
      licensors. You may not reproduce, distribute, modify, or create derivative
      works from the Application without prior written consent from Together.
    </p>

    <h2 class="mb-2 text-2xl font-bold">7. Limitation of Liability</h2>
    <p class="mb-10 text-lg">
      Together will not be liable for any direct, indirect, incidental, special,
      or consequential damages arising from the use or inability to use the
      Application. The Application is provided "as is," without any warranties
      of any kind.
    </p>

    <h2 class="mb-2 text-2xl font-bold">8. Modifications to the Terms</h2>
    <p class="mb-10 text-lg">
      We reserve the right to modify these Terms at any time. Modifications will
      be posted on this page, and continued use of the Application after any
      changes constitutes acceptance of such changes.
    </p>

    <h2 class="mb-2 text-2xl font-bold">9. Termination</h2>
    <p class="mb-10 text-lg">
      We may suspend or terminate your access to the Application at any time if
      you violate these Terms or if we decide to discontinue the Application.
      Termination will not affect rights and obligations accrued before the
      termination date.
    </p>

    <h2 class="mb-2 text-2xl font-bold">10. Contact</h2>
    <p class="mb-10 text-lg">
      If you have any questions or concerns about these Terms or the
      Application, you can contact us at: <a
        href="mailto:together@togetherexperiences.com"
        class="text-blue-600 hover:text-blue-800"
        >together@togetherexperiences.com</a
      >
    </p>

    <p class="mb-4 text-lg">
      ScaleMote Pty Ltd, Suite 302, 13/15 Wentworth Ave, Australia (2000)
    </p>
  </div>
</Page>
