<script lang="ts">
  export let styles = 'absolute inset-0 w-full h-full';
</script>

<svg
  class={styles}
  viewBox="0 0 1440 543"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1477.44 2.46844C1477.44 2.46844 1423.38 299.639 1210.57 245.383C1184.38 239.873 1164.52 230.626 1129.34 209.619C986.554 124.361 1059.67 7.44032 1133.87 74.1802C1143.01 82.3951 1150.6 92.1673 1156.63 102.876C1172.26 130.661 1198.02 213.868 1189.83 283.882C1173.28 425.463 1033.55 591.695 772.434 525.138C389.644 427.565 75.5138 41.0962 -105.229 499.129"
    stroke="#28CCBF"
    stroke-width="4"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
