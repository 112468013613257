<script>
  import { Route } from 'svelte-navigator';
  import Footer from '@/lib/components/footer/Footer.svelte';
  import Navbar from '@/lib/components/navbar/Navbar.svelte';
  import SubmissionForm from '@/lib/components/signup-form/SignupForm.svelte';
  import ForgotPassword from '@/routes/forgot-password/ForgotPassword.svelte';
  import Home from '@/routes/home/Home.svelte';
  import Login from '@/routes/login/Login.svelte';
  import NotFound from '@/routes/NotFound.svelte';
  import Signup from '@/routes/signup/Signup.svelte';

  import Integrations from '../integrations-info/IntegrationsInfo.svelte';
  import JoinLobby from '../join-lobby/JoinLobby.svelte';
  import Lobby from '../lobby/Lobby.svelte';
  import Dices from '../mini-games/dice/Dices.svelte';
  import LuckyCards from '../mini-games/lucky-cards/LuckyCards.svelte';
  import Roulette from '../mini-games/roulette/Roulette.svelte';
  import Privacy from '../privacy/Privacy.svelte';
  import Support from '../support/Support.svelte';
  import Terms from '../terms/Terms.svelte';
</script>

<Navbar />
<Route path="/" component={Home} />
<Route path="/forgot-password" component={ForgotPassword} />
<Route path="/login/couples" component={Login} />
<Route path="/signup/couples" component={Signup} />
<Route path="/login/b2b" component={Login} />
<Route path="/signup/b2b" component={Signup} />
<Route path="/lobby/games/:pin" component={Lobby} />
<Route path="/mini-games/lucky-cards/:id" component={LuckyCards} />
<Route path="/mini-games/roulette/:id" component={Roulette} />
<Route path="/mini-games/dice" component={Dices} />
<Route path="/join-lobby/*pin" component={JoinLobby} />
<Route path="/request-trial" component={SubmissionForm} />
<Route path="/integrations" component={Integrations} />
<Route path="/privacy" component={Privacy} />
<Route path="/terms" component={Terms} />
<Route path="/support" component={Support} />
<Route path="/*" component={NotFound} />
<Footer />
