<script lang="ts">
  export let style = 'h-5 w-5';
</script>

<svg
  class={style}
  xmlns="http://www.w3.org/2000/svg"
  viewBox="-0.5 -0.5 16 16"
  fill="none"
  stroke="currentColor"
  stroke-linecap="round"
  stroke-linejoin="round"
  height="16"
  width="16"
  ><path
    d="M4.828625 6.186C4.440125 6.2071875 4.174375 5.7999374999999995 4.35025 5.452875000000001C4.35825 5.437125 4.3670625 5.4218125 4.376625 5.407L7.0668125 1.132C7.2515 0.799375 7.7236875000000005 0.7824375 7.931749999999999 1.101L10.604625 5.3918124999999995C10.8269375 5.711125 10.62025 6.1513125 10.2325625 6.1841875C10.218937500000001 6.185375 10.205312499999998 6.186 10.191625 6.186Z"
    stroke-width="1"
  /><path
    d="M1.724 9.074H5.334S6.056 9.074 6.056 9.796V13.406S6.056 14.128 5.334 14.128H1.724S1.002 14.128 1.002 13.406V9.796S1.002 9.074 1.724 9.074"
    stroke-width="1"
  /><path
    d="M8.943999999999999 11.600999999999999C8.943999999999999 13.546312499999999 11.0498125 14.762062499999999 12.7345 13.789437499999998C13.516312500000002 13.338062500000001 13.998 12.5038125 13.998 11.600999999999999C13.998 9.65575 11.892125 8.4399375 10.2075 9.4125625C9.425625 9.864 8.943999999999999 10.6981875 8.943999999999999 11.600999999999999"
    stroke-width="1"
  /></svg
>
