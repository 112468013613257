<script lang="ts">
  export let style: 'fill' | 'outline' | 'outline-reverse' = 'fill';
  export let disabled = false;
  export let submit = false;
  export let rounded:
    | 'rounded-sm'
    | 'rounded'
    | 'rounded-md'
    | 'rounded-lg'
    | 'rounded-full' = 'rounded-sm';
  export let testId = '';

  const classStyle = {
    fill: 'text-white bg-primary  px-3 py-2 text-sm hover:bg-accent hover:text-white transition ease-in duration-200',
    outline:
      'text-grey fill-grey hover:fill-white border border-1 border-grey px-3 py-2 text-sm hover:bg-primary hover:border-primary hover:text-white transition ease-in duration-200',
    'outline-reverse':
      'text-white bg-primary fill-grey hover:fill-white border border-1 border-primary px-3 py-2 text-sm hover:bg-white hover:border-grey hover:text-grey transition ease-in duration-200',
    disabled:
      'text-gray-400 fill-gray-400 px-3 py-2 text-sm border border-1 border-gray-400 bg-white',
  };
</script>

<button
  type={submit ? 'submit' : 'button'}
  data-cy={testId}
  class={`${
    disabled ? classStyle['disabled'] : classStyle[style]
  } flex flex-row items-center justify-center gap-1 ${rounded} group `}
  on:click
  disabled={disabled}
>
  <slot />
</button>
