<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import type { IExperience } from '@/interface/IExperience';
  import type { IUser } from '@/interface/IUser';
  import SearchInput from '@/lib/components/search-input/SearchInput.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    createSession,
    getTenantUsers,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import Button from '../../button/Button.svelte';
  import XIcon from '../../icons/XIcon.svelte';
  import Input from '../../input/Input.svelte';
  import InviteUserList from '../../lists/InviteUserList.svelte';
  import Loading from '../../loading/Loading.svelte';
  import { closeModal } from '../modal.store';

  export let onSubmit: (() => void) | undefined = undefined;
  export let experience: IExperience;
  let selectedUsers = new Set<IUser>();
  let sessionName = '';
  let scheduledDate = '';
  let isCreatingSession = false;
  let tenantUsers: IUser[] = [];
  let filteredUsers: IUser[] = [];

  const getUsers = async () => {
    try {
      const { users } = await getTenantUsers();
      tenantUsers = users;

      filteredUsers = tenantUsers;

      return filteredUsers;
    } catch (error) {
      console.error(error);
      ToastNotification.error(
        $t('dashboard.experiences.start-session-fetch-users-error'),
      );
      return [];
    }
  };

  const handleConfirm = async () => {
    isCreatingSession = true;
    try {
      await createSession(
        experience.id,
        $userStore!.id,
        Array.from(selectedUsers).map((user) => user.id),
        sessionName,
        scheduledDate,
      );
      closeModal();
      ToastNotification.success(
        $t('dashboard.experiences.start-session-success'),
      );
      onSubmit?.();
      navigate(`/dashboard/sessions`);
    } catch (error) {
      ToastNotification.error($t('dashboard.experiences.start-session-error'));
    } finally {
      isCreatingSession = false;
    }
  };

  const handleSelect = (user: IUser) => {
    selectedUsers.has(user)
      ? selectedUsers.delete(user)
      : selectedUsers.add(user);
    selectedUsers = new Set(selectedUsers);
  };

  const handleSearch = (event: CustomEvent<any>) => {
    const { searchBy, searchQuery } = event.detail;
    filteredUsers = tenantUsers.filter((user) =>
      searchBy === 'name'
        ? `${user.name} ${user.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  $: buttonDisabled =
    selectedUsers.size < 2 ||
    sessionName.trim() === '' ||
    !sessionName.trim().match(/^[a-zA-Z0-9 ]+$/) ||
    scheduledDate === '';
</script>

<div
  class="flex w-fit flex-col items-center justify-between gap-6 rounded-lg bg-white p-4"
>
  <div class="flex w-full flex-col gap-4">
    <div class="flex w-full flex-row gap-1">
      <div class="flex w-full flex-col gap-1">
        <span class="font-baloo font-bold"
          >{$t('dashboard.experiences.start-session-title')}</span
        >
        <span class="text-sm text-grey">
          {$t('dashboard.experiences.start-session-subtitle')}
        </span>
      </div>
      <div class="h-full">
        <button on:click={closeModal}>
          <XIcon
            style="w-6 h-6 hover:bg-primary hover:text-white rounded-full"
          />
        </button>
      </div>
    </div>
    {#await getUsers()}
      <Loading loadingSize="h-20 w-20" />
    {:then users}
      {#if users}
        <SearchInput
          searchByOptions={['name', 'email']}
          on:search={handleSearch}
        />
        <InviteUserList
          users={filteredUsers}
          selectedUsers={selectedUsers}
          onSelect={handleSelect}
        />
      {:else}
        <p>{$t('dashboard.experiences.start-session-no-users')}</p>
      {/if}
    {/await}
  </div>
  <div class="flex h-full w-full flex-row gap-4 self-start">
    <Input
      testId="session-name-input"
      bind:value={sessionName}
      label={$t('dashboard.experiences.start-session-name-input-label')}
      placeholder={$t(
        'dashboard.experiences.start-session-name-input-placeholder',
      )}
      error={!sessionName.trim().match(/^[a-zA-Z0-9 ]+$/)
        ? $t('dashboard.experiences.start-session-name-input-error')
        : ''}
    />
    <Input
      testId="session-date-input"
      bind:value={scheduledDate}
      label={$t('dashboard.experiences.start-session-date-input-label')}
      type="date"
      minDate={new Date().toISOString().slice(0, 10)}
    />
  </div>
  <div class="flex w-full flex-row items-center justify-end">
    {#if !isCreatingSession}
      <Button
        disabled={buttonDisabled}
        testId="start-session-confirm-button"
        on:click={handleConfirm}
      >
        <span>{$t('dashboard.experiences.start-session-confirm-button')}</span>
      </Button>
    {:else}
      <Loading loadingSize="h-8 w-8" />
    {/if}
  </div>
</div>
