<script lang="ts">
  export let externalStyles = 'flex items-center justify-center';
  export let loadingMessage = '';
  export let loadingSize = 'h-5 w-5';
  export let loadingColor = 'text-primary';
  export let loadingMargin = 'mr-2';
  export let loadingStyles = '';
  export let loadingSpinnerOpacity = 'opacity-75';
  export let loadingSpinnerColor = '';
</script>

<div class={externalStyles} data-cy="loading">
  <svg
    class={`animate-spin ${loadingMargin} ${loadingSize} ${loadingColor} ${loadingStyles}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    />
    <path
      class={`${loadingSpinnerOpacity} ${loadingSpinnerColor}`}
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
  <span>{loadingMessage}</span>
</div>
