<script lang="ts">
  import MultiStep from '@/lib/components/multi-step/MultiStep.svelte';
  import SubmissionForm from '@/lib/components/signup-form/SignupForm.svelte';
  import SignupInfo from '@/routes/signup/SignupInfo.svelte';

  let steps = ['Signup', 'Info'] as const;
  let currentStep: (typeof steps)[number] = steps[0];
  let multiStepForm: MultiStep;
</script>

<div
  class="flex h-[45rem] w-full flex-col items-center justify-center xl:gap-10"
>
  <MultiStep
    styles="w-[12rem] z-20 mb-4"
    bind:this={multiStepForm}
    bind:currentStep={currentStep}
    steps={steps}
  />
  {#if currentStep === 'Signup'}
    <SubmissionForm nextAction={multiStepForm?.nextStep} />
  {/if}
  {#if currentStep === 'Info'}
    <SignupInfo />
  {/if}
</div>
