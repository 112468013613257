<script lang="ts">
  export let styles = 'w-full h-full';
</script>

<svg
  class={styles}
  width="1081"
  height="975"
  viewBox="0 0 1081 975"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1087 -134.837C1087 -134.837 1120.39 109.18 967.769 192.816C949.669 204.017 932.981 208.561 900.716 213.005C769.764 231.039 742.968 96.648 824.131 104.761C834.123 105.758 844.148 108.883 853.83 113.663C878.951 126.07 942.222 175.676 978.492 235.088C1051.84 355.231 1054 858 853.83 952C502 1089 419 510 2.50003 538.5"
    stroke="#28CCBF"
    stroke-width="4"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
