import {
  CognitoUser,
  type CognitoUserPool,
  type CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { get } from 'svelte/store';
import { userPoolStore } from '@/store/cognitoStore';

export const refreshUserToken = async (
  userPool: CognitoUserPool,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (!user) return reject('User is not logged in');

    user.getSession((_: Error, session: CognitoUserSession | null) => {
      if (!session) return reject("User session doesn't exist");
      resolve(session);
    });
  });
};

export const passwordRecovery = async (email: string) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: get(userPoolStore),
  });

  return new Promise<void>((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

export const confirmNewPassword = async (
  newPassword: string,
  code: string,
  email: string,
): Promise<void> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: get(userPoolStore),
  });
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};
