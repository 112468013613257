<script lang="ts">
  import { navigate } from 'svelte-navigator';

  import Input from '../../lib/components/input/Input.svelte';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import Page from '../../lib/components/page/Page.svelte';
  import { ToastNotification } from '../../lib/services/toast';
  import {
    connectGuestSocket,
    joinLobby,
  } from '../../lib/services/together/together.socket.api';
  import type { IJoinForm } from './IJoinForm';
  import { joinSchema } from './join.validation.schema';

  export let pin = '';

  let isLoading = false;
  const joinForm: IJoinForm & { [key: string]: any } = {
    name: { value: '', error: '' },
    pin: { value: pin, error: '' },
  };

  let inputs = [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'John',
      type: 'text',
      value: joinForm.name.value,
      error: joinForm.name.error,
    },
    {
      name: 'pin',
      label: 'Pin',
      type: 'number',
      placeholder: '123456',
      value: joinForm.pin.value,
      error: joinForm.pin.error,
    },
  ];

  const validateForm = () => {
    const validation = joinSchema.validate(
      {
        name: joinForm.name.value,
        pin: joinForm.pin.value,
      },
      { abortEarly: false },
    );
    for (const key in joinForm) {
      joinForm[key as keyof IJoinForm].error = '';
    }

    validation.error?.details.forEach((error) => {
      const fieldName = error.context?.key as keyof IJoinForm;
      joinForm[fieldName].error = error.message;
    });
  };

  const validateField = (fieldName: string) => {
    const fieldSchema = joinSchema.extract(fieldName);
    const { error } = fieldSchema.validate(
      joinForm[fieldName as keyof IJoinForm].value,
      {
        abortEarly: false,
      },
    );

    joinForm[fieldName as keyof IJoinForm].error = (
      error ? error.details[0]?.message : ''
    ) as string;
  };

  const handleSubmit = async () => {
    validateForm();

    for (const key in joinForm) {
      if (joinForm[key as keyof IJoinForm].error) return;
    }

    try {
      isLoading = true;
      const guestClient = await connectGuestSocket();
      const guestPayload = {
        name: joinForm.name.value,
        pin: joinForm.pin.value,
      };
      await joinLobby(guestClient, guestPayload);

      navigate(`/lobby/games/${joinForm.pin.value}`);
    } catch (err: any) {
      ToastNotification.error(err.message);
    } finally {
      isLoading = false;
    }
  };
</script>

<Page>
  <div class="flex w-full max-w-sm flex-col justify-center rounded-lg p-6">
    <h3 class="text-center text-2xl font-bold">Join Lobby</h3>
    <form
      class="flex w-full flex-col items-center gap-3"
      on:submit|preventDefault={handleSubmit}
    >
      {#each inputs as inputField}
        <Input
          styleClass="w-full"
          onChangeValue={() => validateField(inputField.name)}
          bind:value={joinForm[inputField.name].value}
          bind:error={joinForm[inputField.name].error}
          type={inputField.type}
          placeholder={inputField.placeholder}
          label={inputField.label}
          name={inputField.name}
        />
      {/each}

      <button
        class="h-10 w-full rounded-md bg-indigo-500 font-bold text-white transition duration-200 ease-in hover:bg-indigo-700"
        type="submit"
        disabled={isLoading}
      >
        {#if isLoading}
          <Loading
            loadingMargin=""
            externalStyles="flex flex-row justify-center"
            loadingColor="text-white"
            loadingSpinnerOpacity="opacity-100"
          />
        {:else}
          Join
        {/if}
      </button>
    </form>
  </div>
</Page>
