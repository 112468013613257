<script lang="ts">
  import type { IUser } from '@/interface/IUser';
  import { t } from '@/locales/i18n';

  import Button from '../button/Button.svelte';
  import UserIcon from '../icons/UserIcon.svelte';

  export let users: IUser[];

  export let actions: {
    name: string;
    onAction: (user: IUser) => void;
    disabledCondition?: (user: IUser) => boolean;
  }[] = [];
</script>

<table class="w-full text-left text-sm text-dark-grey">
  <thead>
    <tr class="h-10">
      <th scope="col"><span>{$t('dashboard.users.table-name')}</span></th>
      <th scope="col"><span>{$t('dashboard.users.table-email')}</span></th>
      <th scope="col"><span>{$t('dashboard.users.table-role')}</span></th>
      {#if actions.length > 0}
        <th scope="col"><span>{$t('dashboard.users.table-actions')}</span></th>
      {/if}
    </tr>
  </thead>
  <tbody>
    {#each users as user, rowIndex}
      <tr class=" h-16">
        <td>
          <div class="flex flex-row items-center gap-2">
            <UserIcon style="text-grey w-10 h-10" />
            <span>{user.name} {user.lastName}</span>
          </div>
        </td>
        <td><span>{user.email}</span></td>
        <td><span>{user.role}</span></td>

        <td>
          <div class="flex flex-row gap-2">
            {#each actions as { name, onAction, disabledCondition }}
              <Button
                disabled={disabledCondition?.(user) ?? false}
                testId={`${name}-button-${rowIndex}`}
                on:click={() => onAction(user)}
              >
                <span>{name}</span>
              </Button>
            {/each}
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
