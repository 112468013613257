import Joi from 'joi';

export const joinSchema = Joi.object({
  name: Joi.string().min(2).max(20).required().messages({
    'string.empty': 'Name is required',
    'string.min': 'Name should have more than 2 characters',
    'string.max': 'Name should have less than 20 characters',
    'any.required': 'Name is required',
  }),

  pin: Joi.number()
    .integer()
    .positive()
    .min(100000)
    .max(999999)
    .required()
    .messages({
      'number.base': 'Pin is required',
      'number.empty': 'Pin is required',
      'number.integer': 'Pin must be an integer',
      'number.positive': 'Pin must be a positive number',
      'number.min': 'Pin must have at least 6 digits',
      'number.max': 'Pin must have a maximum of 6 digits',
      'any.required': 'Pin is required',
    }),
});
