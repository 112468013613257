<script lang="ts">
  import { closeModal } from '../../lib/components/modal/modal.store';

  const close = () => {
    closeModal();
  };
</script>

<div
  on:click|stopPropagation
  on:keydown
  class="relative flex w-11/12 flex-col items-center justify-center gap-8 rounded-lg bg-white pb-8 pt-10 shadow-lg sm:w-96"
>
  <h1 class="text-3xl font-bold">Thanks for playing</h1>
  <button
    class="h-10 w-16 rounded-md bg-indigo-500 font-bold text-white transition duration-200 ease-in hover:bg-indigo-700"
    on:click={close}>OK</button
  >
</div>
