import Joi from 'joi';

export const confirmPasswordSchema = Joi.object({
  newPassword: Joi.string()
    .regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&.*]).{8,}$/)
    .min(8)
    .messages({
      'string.empty': 'Password is required',
      'string.pattern.base':
        'At least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
      'string.min': 'Password should be at least 8 characters long',
    }),
  repeatPassword: Joi.any().valid(Joi.ref('newPassword')).required().messages({
    'string.empty': 'Confirm password is required',
    'any.only': 'Passwords should match',
    'any.required': 'Confirm password is required',
  }),
});
