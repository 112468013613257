<script lang="ts">
  import { navigate } from 'svelte-navigator';

  export let authCheck: () => boolean;
  export let onReject: (() => void) | undefined = undefined;
  $: {
    const isAuthorized = authCheck();
    if (!isAuthorized) onReject ? onReject() : navigate('/', { replace: true });
  }
</script>
