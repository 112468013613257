<script lang="ts">
  export let id: number;
  export let name: string;
  export let content: string;
  export let showCardBack = false;
  export let suit = 'classic';

  let icon = '';
  let bgColor = '';

  const icons = ['heart', 'club', 'spade', 'diamond'];
  const colors = ['#9b89fa', '#28ccbf', '#6346fa', '#9AC8CD'];

  const getRandomItem = (array: any) =>
    array[Math.floor(Math.random() * array.length)];

  $: if (suit === 'classic') {
    icon = getRandomItem(icons);
    bgColor = 'white';
  } else if (suit === 'color') {
    icon = '';
    bgColor = getRandomItem(colors);
  }

  const toggleShowBack = () => {
    showCardBack = !showCardBack;
  };
</script>

<div class="flip-box col-span-1">
  <div
    class="flip-box-inner"
    class:flip-it={showCardBack}
    data-cy="lucky-cards-{id + 1}"
  >
    <div
      class="flip-box-front"
      style="background-color: {bgColor};"
      on:click={toggleShowBack}
      on:keypress={toggleShowBack}
      data-cy="lucky-cards-{id + 1}-front"
    >
      {#if suit === 'classic'}
        <p
          class="flex flex-col self-center text-black"
          data-cy="lucky-cards-{id + 1}-classic-name"
        >
          {#if icon}
            <i class={`card-icon ${icon}`} />
          {/if}
          <span class="break-all p-1">{name}</span>
        </p>
      {:else if suit === 'color'}
        <p
          class="self-center break-all p-1 text-xl text-black"
          data-cy="lucky-cards-{id + 1}-color-name"
        >
          {name}
        </p>
      {/if}
    </div>

    <div
      class="flip-box-back"
      class:conceal-answer={showCardBack}
      on:click={toggleShowBack}
      on:keypress={toggleShowBack}
      data-cy="lucky-cards-{id + 1}-back"
    >
      <p
        id="card-back-content-{id + 1}"
        class="text-wrap break-all p-1"
        data-cy="lucky-cards-{id + 1}-back-content"
      >
        {content}
      </p>
    </div>
  </div>
</div>

<style>
  .flip-it {
    transform: rotateY(180deg);
  }

  .flip-box {
    background-color: transparent;
    min-width: 150px;
    min-height: 300px;
    width: 150px;
    height: 300px;
    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    transition: transform 0.4s;
    transform-style: preserve-3d;
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: -1px 1px 3px black;
  }

  .flip-box-front {
    color: white;
    display: flex;
    justify-content: center;
  }

  .flip-box-back {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    font-size: 1.3rem;
    transform: rotateY(180deg);
  }

  .conceal-answer {
    animation: revealTextSlowly 1s forwards;
  }

  .card-icon {
    font-size: 4em;
  }
  .card-icon.heart::before {
    content: '♥';
    color: red;
  }
  .card-icon.club::before {
    content: '♣';
    color: black;
  }
  .card-icon.spade::before {
    content: '♠';
    color: black;
  }
  .card-icon.diamond::before {
    content: '♦';
    color: red;
  }

  @keyframes revealTextSlowly {
    to {
      color: black;
    }
  }
</style>
