<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';
  import EyeIcon from '@/assets/svg/EyeIcon.svelte';
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { generateNewPassword } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';

  export let email: string;
  export let currentPassword: string;
  let showPassword = false;

  const navigate = useNavigate();

  const schema = yup.object({
    'new-password': yup
      .string()
      .required($t('login.new-password-form-required'))
      .min(8, $t('login.new-password-form-min'))
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
        $t('login.new-password-form-invalid'),
      ),
    'repeat-password': yup
      .string()
      .oneOf([yup.ref('new-password')], $t('login.new-password-form-mismatch')),
  });

  type FormData = yup.InferType<typeof schema>;

  const { form, errors, isSubmitting } = createForm<FormData>({
    initialValues: {
      'new-password': '',
      'repeat-password': '',
    },
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      try {
        await generateNewPassword(
          email,
          currentPassword,
          values['new-password'],
        );
        navigate('/dashboard');
        ToastNotification.success($t('login.new-password-form-success'));
      } catch (error) {
        ToastNotification.error($t('login.new-password-form-error'));
      }
    },
  });
</script>

<form use:form>
  <div class="mt-3">
    <h3
      class="text-lg font-medium leading-6 text-gray-900"
      data-cy="new-password-title"
    >
      {$t('login.new-password-form-title')}
    </h3>
    <div class="flex items-center justify-between">
      <label for="password" class="text-sm font-medium text-gray-900">
        {$t('login.new-password-form-label')}
      </label>
    </div>
    <div class="mt-2 flex flex-col gap-2">
      <Input
        name="new-password"
        type={showPassword ? 'text' : 'password'}
        placeholder={$t('login.new-password-form-placeholder')}
        testId="new-password-input"
        error={$errors?.['new-password']?.[0] || ''}
        styleClass="mt-2"
      >
        <EyeIcon
          testId="password-toggle-visibility"
          on:click={() => (showPassword = !showPassword)}
          show={showPassword}
          style="w-6 h-6 absolute right-3 text-grey cursor-pointer"
        />
      </Input>
      <Input
        name="repeat-password"
        type={showPassword ? 'text' : 'password'}
        placeholder={$t('login.new-password-form-repeat-placeholder')}
        testId="repeat-password-input"
        error={$errors?.['repeat-password']?.[0] || ''}
        styleClass="mt-2"
      />
    </div>
  </div>
  <div class="mt-6 flex w-full justify-end">
    <Button testId="confirm-password-button" submit rounded="rounded-md">
      {#if $isSubmitting}
        <Loading loadingSize="h-6 w-6" />
      {:else}
        <span class="text-xl"
          >{$t('login.new-password-form-submit-button')}</span
        >
      {/if}
    </Button>
  </div>
</form>
