import { io, type Socket } from 'socket.io-client';
import { get } from 'svelte/store';

import {
  guestSocketSession,
  hostSocketSession,
} from '../../../store/socketSession';
import { ClientEvents } from './events/client.events';
import { ServerEvents } from './events/server.events';

const baseAddress = import.meta.env['VITE_API_URL'];
let client: Socket;

export const connectGuestSocket = () =>
  new Promise<Socket>((resolve, reject) => {
    client = io(baseAddress, {
      transports: ['websocket'],
    });
    client?.on(ServerEvents.exception, (error) => {
      reject(error);
    });
    client?.on(ServerEvents.connectionResponse, () => {
      guestSocketSession.set(client);
      resolve(client);
    });
  });

export const connectHostSocket = async (userToken: string) =>
  new Promise<{ client: Socket; message: string }>((resolve, reject) => {
    client = io(baseAddress, {
      transports: ['websocket'],
      query: { authorization: userToken },
    });
    client.emit(ClientEvents.authentication);
    client?.on(ServerEvents.exception, (error) => {
      if (error.event === ClientEvents.authentication) reject(error);
    });
    client?.on(ServerEvents.authenticationResponse, (message: string) => {
      hostSocketSession.set(client);
      resolve({ client, message });
    });
  });

export const createLobby = (client: Socket): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    client.emit(ClientEvents.createLobby);
    client?.on(ServerEvents.exception, (error) => {
      if (error.event === ClientEvents.createLobby) reject(error);
    });
    client?.on(ServerEvents.createLobbyResponse, (message: { pin: string }) => {
      resolve(message.pin);
    });
  });

interface IGuestPayload {
  name: string;
  pin: string;
}
export const joinLobby = (client: Socket, payload: IGuestPayload) =>
  new Promise<void>((resolve, reject) => {
    client.emit(ClientEvents.joinLobby, payload);
    client?.on(ServerEvents.exception, (error) => {
      if (error.event === ClientEvents.joinLobby) reject(error);
    });
    client?.on(ServerEvents.lobbyNotification, () => {
      resolve();
    });
  });

export const loadBox = (client: Socket, boxId: number): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    client.emit(ClientEvents.loadBox, boxId);
    client?.on(ServerEvents.exception, (error) => {
      if (error.event === ClientEvents.loadBox) reject(error);
    });
    client?.on(ServerEvents.loadBoxResponse, (message: string) => {
      resolve(message);
    });
  });

export const startGame = async (client: Socket, gameName: string) =>
  new Promise<string>((resolve, reject) => {
    client.emit(ClientEvents.startGame, gameName);
    client?.on(ServerEvents.exception, (error) => {
      if (error.event === ClientEvents.startGame) reject(error);
    });
    client.on(ServerEvents.startGameResponse, (message) => {
      resolve(message);
    });
  });

export const endGame = (client: Socket): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    client?.emit(ClientEvents.endGame);
    client?.on(ServerEvents.exception, (error) => {
      if (error.event === ClientEvents.endGame) reject(error);
    });
    client?.on(ServerEvents.gameEvent, (gameEvent) => {
      if (gameEvent.event === ServerEvents.endGameResponse) {
        resolve(gameEvent);
      }
    });
  });

export const setAdmin = (client: Socket, playerId: string): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    client?.emit(ClientEvents.setLobbyAdmin, playerId);
    client?.on(ServerEvents.exception, (error) => {
      if (error.event === ClientEvents.setLobbyAdmin) reject(error);
    });
    client?.on(ServerEvents.gameEvent, (gameEvent) => {
      if (gameEvent.event === ServerEvents.setLobbyAdminResponse) {
        resolve(gameEvent);
      }
    });
  });

export const getClient = () => {
  const hostClient = get(hostSocketSession);
  const guestClient = get(guestSocketSession);

  const client = hostClient ?? guestClient;
  return client;
};
