<script lang="ts">
  import type { ISessionUser } from '@/interface/ISessionUser';
  import { t } from '@/locales/i18n';

  export let sessionUser: ISessionUser;

  const { user, feedbackRating, feedbackComment } = sessionUser;

  const commentLimit = 100;
  let showFullComment = false;
</script>

<div
  class="flex h-fit w-80 flex-col items-start gap-2 rounded-lg bg-white p-6 shadow-lg"
>
  <div>
    <p class="font-bold">
      {user.name.toLowerCase()}
      {user.lastName.toLowerCase()}
    </p>
    <p data-cy="session-user-email" class="text-sm">{user.email}</p>
  </div>
  {#if feedbackRating}
    <div class="flex w-full flex-row items-center justify-between">
      <h4 class="font-bold">{$t('dashboard.sessions.card-rating')}</h4>
      <p class="font-bold">{feedbackRating}</p>
    </div>
    {#if feedbackComment}
      <div>
        <h4 class="font-bold">{$t('dashboard.sessions.card-comment')}</h4>
        {#if feedbackComment.length > commentLimit}
          {#if showFullComment}
            <div class="overflow-y-auto max-h-20">
              <p>{feedbackComment}</p>
            </div>
            <button
              class="mt-4 text-blue-600 hover:text-blue-800 hover:underline hover:cursor-pointer"
              on:click={() => (showFullComment = false)}>View less</button
            >
          {:else}
            <div>
              <p>
                {feedbackComment.substring(0, commentLimit)}...
              </p>
              <button
                class="mt-4 text-blue-600 hover:text-blue-800 hover:underline hover:cursor-pointer"
                on:click={() => (showFullComment = true)}>View all</button
              >
            </div>
          {/if}
        {:else}
          <p>{feedbackComment}</p>
        {/if}
      </div>
    {/if}
  {:else}
    <h4 class="font-bold">{$t('dashboard.sessions.card-feedback-pending')}</h4>
  {/if}
</div>
