export const getGameMap = () => {
  const gameMap: Map<string, string> = new Map();

  gameMap.set('Who is', 'WhoIsGame');
  gameMap.set('True or false', 'TrueOrFalseGame');
  gameMap.set('Double dice board', 'DoubleDiceBoardGame');
  gameMap.set('Written wishes', 'WrittenWishesGame');
  gameMap.set('Icebreaker', 'IcebreakerGame');

  return gameMap;
};
