<script lang="ts">
  import Rizz from '@/assets/svg/Rizz.svelte';
  import SlackLogoExtended from '@/assets/svg/SlackLogoExtend.svg';
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';
</script>

<Page>
  <div class="relative m-1 flex w-full grow items-center justify-center">
    <Rizz />
    <div
      class="absolute inset-0 z-10 mx-8 my-12 flex h-fit flex-col gap-4 text-center md:mx-32 xl:mx-60"
    >
      <div class="mb-4 flex flex-row items-center justify-center">
        <img src={SlackLogoExtended} alt="Slack logo" class="w-28" />
      </div>
      <div
        class="flex w-full flex-col items-start justify-start gap-6 rounded-lg p-14"
      >
        <ul class="w-full list-decimal ps-4" data-cy="integrations-info-list">
          <li class="mb-4 text-start text-2xl text-dark-grey">
            {@html $t('integrations.claim-text-1')}
          </li>
          <li class="mb-4 text-start text-2xl text-dark-grey">
            {@html $t('integrations.claim-text-2')}
          </li>
          <li class="mb-4 text-start text-2xl text-dark-grey">
            {@html $t('integrations.claim-text-3')}
          </li>
          <li class="mb-4 text-start text-2xl text-dark-grey">
            {@html $t('integrations.claim-text-4')}
          </li>
        </ul>
      </div>
    </div>
  </div>
</Page>
