<script lang="ts">
  let url = import.meta.env['VITE_SLACK_OAUTH_URL'];
</script>

<a
  href={url}
  on:click={(e) => {
    e.preventDefault();
    const state = crypto.randomUUID();
    localStorage.setItem('oauthState', state);
    url = `${import.meta.env['VITE_SLACK_OAUTH_URL']}&state=${state}`;
    window.location.href = url;
  }}
  target="_blank"
  rel="noopener noreferrer"
  data-cy="slack-integration-cta"
  ><img
    alt="Add Together App to Slack"
    height="40"
    width="139"
    src="https://platform.slack-edge.com/img/add_to_slack.png"
    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
  /></a
>
