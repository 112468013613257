import * as Sentry from '@sentry/svelte';

import App from './App.svelte';

const enableSentry =
  import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging';

if (enableSentry) {
  Sentry.init({
    dsn: 'https://f576c670d2712bc7198a5ef3ffa593d7@o4507297625145344.ingest.us.sentry.io/4507611979382784',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.MODE,
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
const app = new App({
  target: document.getElementById('app')!,
});

export default app;
