<script lang="ts">
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
  import { onMount } from 'svelte';
  import CircleXMark from '@/assets/svg/CircleXMark.svelte';
  import { get } from '@/lib/services/axios/api-axios';

  import type { IMiniGame } from '../@types/IMiniGamesResponse';
  import { MiniGamesEnum } from '../@types/MiniGames.enum';

  export let id: number;
  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D | null = null;
  let width: number;
  let height: number;
  let items: { item: string; description: string; index: number }[];
  let arcStep: number;
  const brandBookFirstColorDefault = { r: 99, g: 70, b: 250 };
  let brandBookFirstColorsSelected: { r: number; g: number; b: number };
  const brandBookSecondColorDefault = { r: 40, g: 204, b: 191 };
  let brandBookSecondColorsSelected: { r: number; g: number; b: number };
  let colors: { r: number; g: number; b: number; a: number }[];
  let colorsString: string[];
  let currentDeg = 0;
  let speed = 0;
  let maxRotation: number;
  let pause = false;
  let winner: number;
  let showNumbers: boolean;
  let showItems: boolean;
  let showDescriptions: boolean;
  let undoTrail: { item: string; description: string; index: number }[][] = [];

  onMount(() => {
    canvas = document.getElementById('canvas') as HTMLCanvasElement;
    ctx = canvas.getContext('2d');
    width = canvas.width;
    height = canvas.height;
    loadMiniGameRoulette();
  });

  const loadMiniGameRoulette = async () => {
    try {
      const response = await get<IMiniGame>(
        `/mini-game/${id}?gameName=${MiniGamesEnum.ROULETTE}`,
      );
      const miniGameContent = JSON.parse(response.content);
      brandBookFirstColorsSelected =
        miniGameContent?.brandBookFirstColorsSelected ??
        brandBookFirstColorDefault;
      brandBookSecondColorsSelected =
        miniGameContent?.brandBookSecondColorsSelected ??
        brandBookSecondColorDefault;
      showNumbers = miniGameContent?.showNumbers ?? true;
      showItems = miniGameContent?.showItems ?? true;
      showDescriptions = miniGameContent?.showDescription ?? false;
      items =
        miniGameContent?.items.map((item, index) => ({ ...item, index })) ?? [];
      arcStep = 360 / items.length;
      colorArrayFilling();
      draw();
    } catch (error) {
      throw "Error loading mini game roulette from API. Maybe they don't exist or the link not corresponds to a mini game roulette";
    }
  };

  const colorArrayFilling = () => {
    colors = [];
    for (let i = 0; i < items.length; i++) {
      colors[i] = colorDistribution(i);
    }

    colorsString = [];
    for (let i = 0; i < colors.length; i++) {
      colorsString[i] =
        `rgba(${colors[i]?.r},${colors[i]?.g},${colors[i]?.b},${colors[i]?.a})`;
    }
  };

  const colorDistribution = (
    i: number,
  ): { r: number; g: number; b: number; a: number } => {
    let color;
    const DIVISOR_FOR_EVEN_CHECK = 2;
    const areItemsEven = items.length % DIVISOR_FOR_EVEN_CHECK === 0;
    const oddExceptionNumbers = [7, 13, 19, 25, 31, 37, 43, 49];
    const colorDistributionOddException = oddExceptionNumbers.includes(
      items.length,
    );
    const lastItem = i === items.length - 1;

    if (areItemsEven) {
      color =
        i % 2 === 0
          ? { ...brandBookFirstColorsSelected, a: 1 }
          : { ...brandBookSecondColorsSelected, a: 1 };
    } else {
      if (i % 3 === 0) {
        color = { ...brandBookFirstColorsSelected, a: 1 };
      } else if (i % 3 === 1) {
        color = { ...brandBookSecondColorsSelected, a: 1 };
      } else {
        color = { ...brandBookFirstColorsSelected, a: 0.5 };
      }
    }

    if (colorDistributionOddException && lastItem) {
      color = { ...brandBookSecondColorsSelected, a: 1 };
    }

    return color;
  };

  const toRad = (deg: number): number => {
    return deg * (Math.PI / 180);
  };

  const easeOutSine = (x: number): number => {
    return Math.sin((x * Math.PI) / 2);
  };

  const getPercent = (input: number, min: number, max: number): number => {
    return (input - min) / (max - min);
  };

  const draw = () => {
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = width / 2;
    if (!ctx) return;

    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, toRad(0), toRad(360));
    ctx.fillStyle = `rgb(${175},${175},${175})`;
    ctx.lineTo(centerX, centerY);
    ctx.fill();

    ctx.beginPath();
    ctx.arc(centerX, centerY, radius - 2, toRad(0), toRad(360));
    ctx.fillStyle = `rgba(${250},${250},${250},1)`;
    ctx.lineTo(centerX, centerY);
    ctx.fill();

    let startDeg = currentDeg;
    for (let i = 0; i < items.length; i++, startDeg += arcStep) {
      const endDeg = startDeg + arcStep;
      const color = colors[i];
      if (!color) return;
      const colorStyle = `rgba(${color.r},${color.g},${color.b},${color.a})`;
      const colorStyle2 = `rgba(${color.r},${color.g},${color.b},${
        color.a - 0.25
      })`;

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius - 2, toRad(startDeg), toRad(endDeg));
      ctx.fillStyle = colorStyle2;
      ctx.lineTo(centerX, centerY);
      ctx.fill();

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius - 14, toRad(startDeg), toRad(endDeg));
      ctx.fillStyle = colorStyle;
      ctx.lineTo(centerX, centerY);
      ctx.fill();

      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(toRad((startDeg + endDeg) / 2));
      ctx.textAlign = 'center';
      ctx.fillStyle = '#fff';
      ctx.font = 'bold 24px system-ui';
      if (showItems) {
        ctx.fillText(items[i]?.item ?? '', 130, 6);
      }
      if (showNumbers) {
        ctx.translate(radius - 40, 0);
        ctx.rotate(900);
        ctx.fillText(`${items[i].index + 1}`, 0, 0);
      }
      ctx.restore();

      if (
        startDeg % 360 < 360 &&
        startDeg % 360 > 360 - arcStep &&
        endDeg % 360 > 0 &&
        endDeg % 360 < arcStep
      ) {
        winner = i;
      }
    }
  };

  const animate = () => {
    if (pause) {
      return;
    }
    speed = easeOutSine(getPercent(currentDeg, maxRotation, 0)) * 20;
    if (speed < 0.01) {
      speed = 0;
      pause = true;
    }
    currentDeg += speed;
    draw();
    window.requestAnimationFrame(animate);
  };

  const spin = () => {
    if (speed !== 0) {
      return;
    }
    maxRotation = randomWinnerDefinition();
    currentDeg = 0;
    draw();
    pause = false;
    window.requestAnimationFrame(animate);
  };

  const randomWinnerDefinition = (): number => {
    const randomWinner = Math.floor(Math.random() * items.length);
    const turns = Math.floor(Math.random() * 4) + 3;
    const plusRotation =
      arcStep * randomWinner + Math.floor(Math.random() * arcStep);
    return 360 * turns - plusRotation;
  };

  const discardItem = (i: number) => {
    undoTrail.push(JSON.parse(JSON.stringify(items)));
    undoTrail = undoTrail;
    items.splice(i, 1);
    items = items;
  };

  const undoDiscardItem = () => {
    items = undoTrail[undoTrail.length - 1] ?? items;
    items = items;
    undoTrail.pop();
    undoTrail = undoTrail;
  };

  $: {
    if (items) {
      arcStep = 360 / items.length;
      colorArrayFilling();
      draw();
    }
  }
</script>

<div
  class="m-auto mt-2 grid w-[80%]"
  style="grid-template-columns: 3fr 1.5fr"
  data-cy="rolette-section"
>
  <div>
    <div
      class="flex flex-col items-center justify-center px-6 pt-1 text-justify font-medium"
    >
      <p
        class="h-32 text-[22px] leading-[30px]"
        data-cy="mini-game-roulette-winner"
      >
        {#if winner !== undefined}
          <p
            style="font-size: 28px; text-align: center; font-weight: bold; color: {colorsString[
              winner
            ]}"
          >
            {showNumbers ? items[winner].index + 1 + '.' : ''}
            {showItems ? items[winner]?.item : ''}
          </p>
          {showDescriptions ? items[winner]?.description : ''}
        {:else}
          No winner yet
        {/if}
      </p>
    </div>

    <div class="relative flex justify-center">
      <canvas
        id="canvas"
        width="500"
        height="500"
        data-cy="mini-game-roulette-canvas"
      />
      <button
        class="absolute top-[50%] h-[60px] w-[60px] translate-y-[-50%] rounded-[60px] border-2 border-solid border-neutral-500 bg-white"
        on:click={spin}
      >
        <div
          class="border-t-solid border-b-solid border-r-solid absolute right-[-400%] top-[50%] h-0 w-0 translate-y-[-50%] border-b-[20px] border-r-[60px] border-t-[20px] border-b-transparent border-r-neutral-500 border-t-transparent"
        >
          <div
            class="top-[50%] translate-y-[-50%] border-b-[16px] border-r-[59px] border-t-[16px] border-solid border-b-transparent border-r-white border-t-transparent"
          />
        </div>
      </button>
    </div>
  </div>

  <div class="flex flex-col items-center justify-center">
    <button
      class="{undoTrail.length > 0
        ? 'bg-indigo-500  hover:bg-indigo-700'
        : 'cursor-default bg-indigo-100'} mt-2 flex items-center justify-center rounded-md px-3 py-2 font-bold text-white transition duration-200 ease-in"
      on:click={undoDiscardItem}
      disabled={undoTrail.length === 0}>undo item deletion</button
    >

    <div
      class="ml-1 mt-8 w-[80%] pb-1 text-justify text-sm italic leading-none text-gray-300 hover:font-medium hover:text-gray-700"
    >
      *To eliminate an item that has already won, click on the white cross
      besides its. Or the button to undo the action.*
    </div>
    {#if items}
      <div
        class="justify-right mt-0 w-[80%] select-none rounded-md border-2 border-gray-300 bg-gray-100 p-2"
      >
        {#each items as option, i}
          <ol>
            <li class="mb-1 mt-1">
              <div
                class="group rounded-md border-[1px] border-gray-200 px-2 shadow-sm hover:border"
              >
                <div class="flex justify-between px-1">
                  <span
                    style={`font-weight: bold;color: ${colorsString[i]}`}
                    class="flex w-[90%] select-none bg-gray-100 p-0"
                  >
                    {showNumbers ? option.index + 1 + '.' : ''}
                    {showItems ? option.item : ''}
                  </span>
                  <button
                    class="z-10 w-6 cursor-pointer rounded-full group-hover:bg-gray-200 group-hover:shadow-lg"
                    on:click={() => discardItem(i)}
                  >
                    <CircleXMark color={'text-white'} />
                  </button>
                </div>
                {#if showDescriptions}
                  <div>
                    {option.description}
                  </div>
                {/if}
              </div>
            </li>
          </ol>
        {/each}
      </div>
    {/if}
  </div>
</div>
