<script lang="ts">
  import Page from '@/lib/components/page/Page.svelte';
</script>

<Page>
  <div class="mx-auto mb-8 max-w-2xl p-4 md:p-6 lg:p-8">
    <h1
      class="mb-8 text-4xl font-bold text-primary"
      data-cy="privacy-page-title"
    >
      Privacy Policy for Together
    </h1>

    <h2 class="text-2xl font-bold">1. Introduction</h2>
    <p class="mb-10 text-lg">
      Welcome to Together! This Privacy Policy outlines how Together ("we",
      "our", "us") collects, uses, and protects your information when you use
      the Together platform (the "Service"). By using our Service, you agree to
      the terms and conditions of this Privacy Policy.
    </p>

    <h2 class="text-2xl font-bold">2. Information We Collect</h2>
    <p class="mb-4 text-lg">We collect the following types of information:</p>
    <ul class="mb-10 text-lg">
      <li>
        <p>2.1 Personal Information</p>
        <ul class="mb-4 list-disc pl-4">
          <li>Name</li>
          <li>Email address</li>
          <li>Password (encrypted)</li>
          <li>Team and organisation details</li>
        </ul>
      </li>
      <li>
        <p>2.2 Usage Data</p>
        <ul class="mb-4 list-disc pl-4">
          <li>Activities conducted on the platform</li>
          <li>Feedback provided, including star ratings and comments</li>
          <li>Logs of user interactions with the Service</li>
        </ul>
      </li>
    </ul>

    <h2 class="text-2xl font-bold">3. How We Use Your Information</h2>
    <p class="mb-4 text-lg">
      We use the collected information for the following purposes:
    </p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li>To provide and maintain our Service</li>
      <li>To process your registration and manage your account</li>
      <li>To enhance communication and collaboration within remote teams</li>
      <li>To improve our Service based on user feedback and activity</li>
      <li>
        To communicate with you regarding updates, promotions, and other
        information relevant to your use of our Service
      </li>
    </ul>

    <h2 class="text-2xl font-bold">4. Information Sharing and Disclosure</h2>
    <p class="mb-4 text-lg">
      We do not sell or lease your personal information to third parties.
      However, we may share your information with:
    </p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li>
        Service Providers: To help us operate our business and perform essential
        functions such as hosting and maintenance.
      </li>
      <li>
        Third-party integrations: Such as Slack, Miro, and Cognito, to enhance
        the functionality of our Service.
      </li>
      <li>
        Compliance with Laws: When required by law or to protect our rights,
        safety, and property.
      </li>
    </ul>

    <h2 class="text-2xl font-bold">5. Data Security</h2>
    <p class="mb-4 text-lg">
      We implement industry-standard security measures to protect your
      information. This includes using encryption and secure protocols (e.g.,
      HTTPS). However, no method of transmission over the Internet or electronic
      storage is completely secure.
    </p>
    <p class="mb-4 text-lg">To prevent any vulnerabilities we:</p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li>
        Perform regular backups and have disaster recovery strategies in place
        to guarantee data integrity during unforeseen events.
      </li>
      <li>
        Conduct internal audits of our application to identify and address
        potential vulnerabilities.
      </li>
      <li>
        Limit access to personal information to authorised personnel and your
        organisation’s members through role-based access controls, minimising
        possible risks.
      </li>
      <li>
        Utilise AWS Cognito for user authentication to enhance the security of
        stored passwords and reduce risks.
      </li>
    </ul>

    <h2 class="text-2xl font-bold">6. Data Retention</h2>
    <p class="mb-10 text-lg">
      We retain your personal information (name, surname, email, and
      organisation name) for as long as necessary to fulfil the purposes
      described in this Privacy Policy, including providing our services and
      complying with legal requirements. If there is no ongoing legitimate
      business need to process your personal information, we will either delete
      or anonymize it. Currently, we do not have a periodic data deletion
      mechanism, but we are committed to reviewing our practices regularly.
      Users may contact us to request the deletion of their personal
      information, and we will accommodate such requests in accordance with
      applicable laws.
    </p>

    <h2 class="text-2xl font-bold">7. Your Data Protection Rights</h2>
    <p class="mb-4 text-lg">
      Depending on your location, you may have certain rights regarding your
      personal data, including:
    </p>
    <ul class="mb-4 list-disc pl-4 text-lg">
      <li>The right to access</li>
      <li>The right to rectification</li>
      <li>The right to erasure</li>
      <li>The right to restrict processing</li>
      <li>The right to object to processing</li>
      <li>The right to data portability</li>
    </ul>
    <p class="mb-10 text-lg">
      To exercise these rights, please contact us at <a
        href="mailto:together@togetherexperiences.com"
        class="text-blue-600 hover:text-blue-800"
        >together@togetherexperiences.com</a
      >.
    </p>

    <h2 class="text-2xl font-bold">
      8. Compliance with Regulatory Requirements
    </h2>
    <p class="mb-10 text-lg">
      We comply with key regulations such as GDPR and implement necessary
      measures to ensure ongoing compliance.
    </p>

    <h2 class="text-2xl font-bold">9. Changes to This Privacy Policy</h2>
    <p class="mb-10 text-lg">
      We may update this Privacy Policy from time to time. Any changes will be
      posted on this page, and where appropriate, notified to you by email or
      through our Service.
    </p>

    <h2 class="text-2xl font-bold">10. Contact Us</h2>
    <p class="mb-4 text-lg">
      If you have any questions or concerns about this Privacy Policy, please
      contact us at:
    </p>
    <ul class="mb-6 text-lg">
      <li>
        Email: <a
          href="mailto:together@togetherexperiences.com"
          class="text-blue-600 hover:text-blue-800"
          >together@togetherexperiences.com</a
        >
      </li>
      <li>
        Address: ScaleMote Pty Ltd, Suite 302, 13/15 Wentworth Ave, Australia
        (2000)
      </li>
    </ul>

    <p class="text-lg">
      Thank you for trusting Together with your data. We are committed to
      protecting your privacy and providing a secure experience for all users.
    </p>
  </div>
</Page>
