<script lang="ts">
  import Dice from '@/lib/components/mini-games/dice/Dice.svelte';

  let twoDices = false;

  let dice1: Dice;
  let dice2: Dice;

  const rollDices = async () => {
    if (twoDices) {
      await Promise.all([dice1.rollDice(), dice2.rollDice()]);
      return;
    }
    await dice1.rollDice();
  };
</script>

<div class="flex grow flex-col items-center justify-center">
  <div class="flex w-fit flex-row items-center justify-between gap-24 p-6">
    <Dice bind:this={dice1} />
    {#if twoDices}
      <Dice bind:this={dice2} />
    {/if}
  </div>

  <div class="mx-auto flex w-[20%] justify-between p-6">
    <button
      class="mt-2 flex items-center justify-center rounded-md bg-indigo-500 px-3 py-2 font-bold text-white transition duration-200 ease-in hover:bg-indigo-700"
      on:click={rollDices}
    >
      Roll
    </button>
    <button
      class="mt-2 flex items-center justify-center rounded-md bg-indigo-500 px-3 py-2 font-bold text-white transition duration-200 ease-in hover:bg-indigo-700"
      on:click={() => (twoDices = !twoDices)}
    >
      {twoDices ? 'One Dice' : 'Two Dices'}
    </button>
  </div>
</div>
