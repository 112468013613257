<script lang="ts">
  import { UserRole, type IUser } from '@/interface/IUser';
  import { ToastNotification } from '@/lib/services/toast';
  import { updateUser } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  import Button from '../../button/Button.svelte';
  import { closeModal } from '../modal.store';

  export let user: IUser;
  export let onSubmit: (() => void) | undefined = undefined;

  let userRole = user.role;

  const handleConfirm = async () => {
    try {
      await updateUser(user.id, { role: userRole });
      ToastNotification.success($t('dashboard.users.assign-role-success'));
      closeModal();
      onSubmit?.();
    } catch (error) {
      ToastNotification.error($t('dashboard.users.assign-role-error'));
    }
  };
</script>

<div
  class="flex h-60 w-80 flex-col items-center justify-between rounded-lg bg-white p-6"
>
  <h1 class="text-2xl font-bold text-dark-grey">
    {@html $t('dashboard.users.assign-role-title', { name: user.name })}
  </h1>
  <select
    class={`w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600`}
    bind:value={userRole}
    name="user-role"
  >
    <option value={UserRole.ADMIN}>Admin</option>
    <option value={UserRole.HOST}>Host</option>
    <option value={UserRole.USER}>User</option>
  </select>
  <div class="flex w-full flex-row items-center justify-between">
    <Button style="outline" on:click={() => closeModal()}
      ><span>{$t('dashboard.users.assign-role-close')}</span></Button
    >
    <Button on:click={handleConfirm}
      ><span>{$t('dashboard.users.assign-role-confirm')}</span></Button
    >
  </div>
</div>
