import { ServerEvents } from '../../lib/services/together/events/server.events';
import { getClient } from '../../lib/services/together/together.socket.api';

export const onLobbyStatus = (callback: (lobby: any) => void) => {
  const client = getClient();
  client?.on(ServerEvents.lobbyStatus, callback);
};

export const onLobbyNotification = (callback: (message: string) => void) => {
  const client = getClient();
  client?.on(ServerEvents.lobbyNotification, callback);
};

export const onClientNotification = (callback: (message: string) => void) => {
  const client = getClient();
  client?.on(ServerEvents.clientNotification, callback);
};

export const onMenuStatus = (callback: (menu: any) => void) => {
  const client = getClient();
  client?.on(ServerEvents.menuStatusResponse, callback);
};

export const onGameEvent = (callback: (gameEvent: any) => void) => {
  const client = getClient();
  client?.on(ServerEvents.gameEvent, callback);
};

export const onLobbyShutdown = (callback: () => void) => {
  const client = getClient();
  client?.on(ServerEvents.lobbyShutdown, callback);
};
