<script lang="ts">
  import QRCode from 'qrcode';
  import { afterUpdate, onMount } from 'svelte';

  export let textCode: string;
  export let squareSize = 116;
  export let darkColorQR = '#000000ff';
  export let whiteColorQR = '#ffffffff';
  export let marginQR = 4;
  export let typeQR = 'image/jpeg';

  let hasError = false;
  let errorMessage: string;
  let previousTextCode: string;

  const options = {
    type: typeQR,
    margin: marginQR,
    color: {
      dark: darkColorQR,
      light: whiteColorQR,
    },
    width: squareSize,
  };

  const generateQRCode = (canvasElement: HTMLCanvasElement, url: string) => {
    QRCode.toCanvas(canvasElement, url, options, function (error: unknown) {
      if (error) {
        hasError = true;
        errorMessage = `QRCode creation - ${error as string}`;
      }
    });
  };

  const useQRCodeCanvas = (
    node: HTMLCanvasElement,
    { url }: { url: string },
  ) => {
    generateQRCode(node, url);

    return {
      update({ url: newUrl }: { url: string }) {
        generateQRCode(node, newUrl);
      },
    };
  };

  onMount(() => {
    previousTextCode = textCode;
  });

  afterUpdate(() => {
    if (previousTextCode !== textCode) {
      hasError = false;
      previousTextCode = textCode;
    }
  });
</script>

{#if hasError}
  <p role="alert" class="text-lg font-bold text-red-500">{errorMessage}</p>
{:else}
  <canvas data-testid="qr-canvas" use:useQRCodeCanvas={{ url: textCode }} />
{/if}
