import type { IExperience } from './IExperience';
import type { ISessionUser } from './ISessionUser';
import type { IUser } from './IUser';

export enum SessionStatus {
  STARTED = 'Pending',
  FINISHED = 'Finished',
}
export interface ISession {
  id: number;
  experience: IExperience;
  name: string;
  scheduledDate: string;
  host: IUser;
  users: ISessionUser[];
  status: SessionStatus;
  boardUrl: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}
