<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import AboutUsImg from '@/assets/img/about-us-img.jpg';
  import LandingPageInfoElement from '@/assets/svg/LandingPageInfoElement.svelte';
  import Rizz from '@/assets/svg/Rizz.svelte';
  import { ExperienceLanguages } from '@/interface/IExperience';
  import AuthGuard from '@/lib/components/auth-guard/AuthGuard.svelte';
  import ExperienceCard from '@/lib/components/cards/ExperienceCard.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import { getLandingExperienceCards } from '@/lib/services/together/together.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let isLogged = $userStore !== null;
  let selectedLanguage: string;

  $: $locale,
    (selectedLanguage =
      $locale === 'en'
        ? ExperienceLanguages.english
        : ExperienceLanguages.spanish);

  const getLandingExperiences = async (language: string) => {
    const { experiences } = await getLandingExperienceCards(language);
    return experiences;
  };

  $: $locale, getLandingExperiences(selectedLanguage);
</script>

<svelte:head>
  <title>Together</title>
</svelte:head>

<AuthGuard
  authCheck={() => !isLogged}
  onReject={() => navigate('/dashboard')}
/>
<Page>
  <div class="relative mb-4 h-screen w-full grow">
    <Rizz />
    <div class="absolute inset-0 z-10 flex items-center justify-center">
      <div class="text-center">
        <h1
          class="mb-6 font-bogart-bold text-4xl text-primary sm:text-6xl md:text-8xl"
          data-cy="landing-page-title"
        >
          {@html $t('home.title')}
        </h1>
        <p
          class="mb-12 font-baloo text-lg md:text-xl"
          data-cy="landing-page-description"
        >
          {@html $t('home.subtitle')}
        </p>
        <a
          class="rounded bg-primary px-4 py-2 text-white transition duration-200 ease-in hover:bg-primary/50"
          href="/signup"
          data-cy="landing-page-request-trial-cta-hero"
          >{@html $t('home.cta-button')}</a
        >
      </div>
    </div>
  </div>
  <div
    class="mx-4 my-4 mb-24 flex h-full flex-col rounded-lg lg:mx-24 xl:flex-row-reverse"
  >
    <div class="flex items-center justify-center">
      <LandingPageInfoElement
        styles="object-cover rounded-lg h-[18rem] xl:h-[18rem] 2xl:h-[30rem] 2xl:pe-16"
      />
    </div>
    <div
      id="info-section"
      class="flex flex-1 flex-col justify-between px-4 py-10 lg:px-16 2xl:max-w-fit"
    >
      <div class="flex flex-col gap-5">
        <h2
          class="w-full text-center font-bogart text-3xl font-bold text-primary md:text-4xl lg:text-start"
        >
          {@html $t('home.info-title')}
        </h2>
        <p class="text-justify font-baloo text-base leading-snug md:text-base">
          {@html $t('home.info-text-1')}
        </p>
        <h3 class="mt-4 w-full text-center font-bold lg:text-start">
          {@html $t('home.info-subtitle')}
        </h3>
        <p class="text-justify font-baloo text-base leading-snug md:text-base">
          {@html $t('home.info-text-2')}
        </p>
      </div>
      <div class="flex justify-center pt-16 md:justify-start md:pt-8">
        <a
          class="rounded bg-primary px-4 py-2 text-white transition duration-200 ease-in hover:bg-primary/50"
          href="/signup"
          data-cy="landing-page-request-trial-cta">{$t('home.cta-button')}</a
        >
      </div>
    </div>
  </div>
  <div class="my-4 mb-24 flex h-full w-full px-0">
    <div
      id="catalogue-section"
      class="mx-4 flex w-full flex-col items-center justify-between gap-5 lg:mx-40"
    >
      <h3
        class="font-bogart text-3xl font-bold text-primary md:text-4xl lg:self-start"
      >
        {@html $t('home.catalogue-title')}
      </h3>
      <div
        class="grid w-full grid-cols-1 justify-items-center gap-10 md:grid-cols-2 xl:grid-cols-3 xl:gap-20"
      >
        {#await getLandingExperiences(selectedLanguage)}
          <div class="col-span-3 w-full">
            <Loading loadingSize="w-48" />
          </div>
        {:then experiences}
          {#each experiences as experience}
            <ExperienceCard experience={experience} isLandingPageCard />
          {/each}
        {/await}
      </div>
    </div>
  </div>
  <div
    class="mx-4 my-4 mb-24 flex h-full flex-col rounded-lg xl:flex-row-reverse 2xl:mx-32"
  >
    <div class="flex items-center justify-center xl:bg-white">
      <img
        class="rounded-lg object-cover md:h-[18rem] xl:pe-16 2xl:h-[30rem] 2xl:pe-10"
        src={AboutUsImg}
        alt="About us"
      />
    </div>
    <div
      id="about-us-section"
      class="mx-4 flex flex-1 flex-col justify-center pb-10 pt-16 lg:mx-36 xl:pt-0 2xl:mx-8"
    >
      <div class="flex flex-col items-center gap-5">
        <h2
          class="w-full text-center font-bogart text-3xl font-bold text-primary md:text-4xl lg:text-start"
        >
          {@html $t('home.aboutus-title')}
        </h2>
        <p class="w-full text-justify">
          {@html $t('home.aboutus-text-1')}
        </p>
        <p class="w-full text-justify">
          {@html $t('home.aboutus-text-2')}
        </p>
        <p class="w-full text-justify">
          {@html $t('home.aboutus-text-3')}
        </p>
        <ul class="w-full list-disc ps-4">
          <li>{@html $t('home.aboutus-list-1')}</li>
          <li>{@html $t('home.aboutus-list-2')}</li>
          <li>{@html $t('home.aboutus-list-3')}</li>
        </ul>
        <div class="flex justify-start self-start pt-16 md:pt-4">
          <a
            class="rounded bg-primary px-4 py-2 text-white transition duration-200 ease-in hover:bg-primary/50"
            href="/signup"
            data-cy="landing-page-request-trial-cta-about-us"
            >{@html $t('home.cta-button')}</a
          >
        </div>
      </div>
    </div>
  </div>
</Page>
