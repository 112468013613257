<script lang="ts">
  export let items: string[] = [];
  export let textContent = '';
  export let textContentSize = 'text-lg';
  export let menuBackgroundColor = 'bg-white';
  export let mainButtonTextColor = 'text-white';
  export let mainButtonScale = 'scale-110';
  export let mainButtonXPadding = 'px-4';
  export let mainButtonYPadding = 'py-1';
  export let mainButtonSize = 'w-full h-full';
  export let position: 'isTop' | 'isBottom' | undefined = undefined;
  export let title = 'Dropdown button';
  export let externalDivStyleClass = '';
  export let mainButtonStyles = '';

  let isDropdownOpen = false;

  const DropdownOptions = {
    isTop: 'bottom-full -left-8 right-0 mb-1 py-2 w-[8rem]',
    isBottom: 'top-full -left-8 right-0 mt-2 py-2 w-[8rem]',
  };

  let currentOptions: string;

  const handleMenuClick = () => {
    isDropdownOpen = !isDropdownOpen;
  };

  $: {
    if (position === 'isTop') {
      currentOptions = DropdownOptions.isTop;
    } else if (position === 'isBottom') {
      currentOptions = DropdownOptions.isBottom;
    }
  }
</script>

<div class={`relative ${externalDivStyleClass}`}>
  <button
    class={`relative inline-flex items-center ${mainButtonXPadding} ${mainButtonYPadding} ${mainButtonSize} ${mainButtonStyles} rounded-md font-medium ${mainButtonTextColor} transition duration-100 ease-in hover:${mainButtonScale}`}
    type="button"
    id="dropdown-button"
    title={`${title}`}
    on:click|stopPropagation={handleMenuClick}
  >
    <slot name="dropdown-icon" />
    <span class={`inline-block align-middle ${textContentSize}`}
      >{textContent}</span
    >
  </button>
  {#if isDropdownOpen}
    <slot name="dropdown-content">
      <div
        class={`relative flex flex-col items-center ${currentOptions} ${menuBackgroundColor} rounded-md shadow-lg`}
      >
        {#if items.length > 0}
          <slot name="dropdown-items">
            {#each items as item}
              <button
                title={item}
                class="block px-3 py-2 text-center text-sm text-gray-700 hover:bg-gray-100"
                >{item}</button
              >
            {/each}
          </slot>
        {/if}
      </div>
    </slot>
  {/if}
</div>
