<script lang="ts">
  import type { ComponentProps } from 'svelte';
  import { type IUser } from '@/interface/IUser';
  import type { IUserQuery } from '@/interface/IUserQuery';
  import Button from '@/lib/components/button/Button.svelte';
  import PlusIcon from '@/lib/components/icons/PlusIcon.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import AssignRoleModal from '@/lib/components/modal/modals/AssignRoleModal.svelte';
  import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
  import InviteUsersModal from '@/lib/components/modal/modals/InviteUsersModal.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import SearchUserInput from '@/lib/components/search-input/SearchInput.svelte';
  import UserTable from '@/lib/components/tables/UserTable.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { getTenantUsers } from '@/lib/services/together/together.api';
  import {
    deleteUser,
    resendUserCredentials,
  } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let query: IUserQuery = {
    name: '',
    lastName: '',
    email: '',
    limit: 7,
    offset: 0,
  };

  let users: IUser[] = [];
  let filteredUsers: IUser[] = [];
  let count = 0;

  const fetchUsers = async () => {
    const { users: newUsers, count: newCount } = await getTenantUsers(query);
    users = newUsers;
    filteredUsers = users;
    count = newCount;
  };

  const handleAssignRole = (user: IUser) =>
    openModal(AssignRoleModal, {
      allowCloseClick: false,
      props: { user, onSubmit: fetchUsers },
    });

  const handleInviteUsers = () => {
    openModal(InviteUsersModal, {
      onClose: fetchUsers,
    });
  };

  const handleResendCredentials = async (user: IUser) => {
    const resendCredentials = async (user: IUser) => {
      try {
        await resendUserCredentials(user);
        ToastNotification.success('Credentials sent successfully!');
      } catch (error) {
        ToastNotification.error('Error sending credentials');
      }
    };

    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        text: 'Resend credentials to ' + user.email,
        onSubmit: () => resendCredentials(user),
      },
    });
  };

  const handleDeleteUser = async (user: IUser) => {
    const confirmDeleteUser = async (user: IUser) => {
      try {
        await deleteUser(user);
        if (users.length === 1 && query.offset > 0) {
          query.offset = query.offset - query.limit;
        }
        fetchUsers();
        ToastNotification.success('User deleted successfully!');
      } catch (error) {
        ToastNotification.error('Error deleting user');
      }
    };

    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        title: 'Confirm',
        text:
          'Are you sure you want to delete the user with email ' + user.email,
        onSubmit: () => confirmDeleteUser(user),
      },
    });
  };

  const actions: ComponentProps<UserTable>['actions'] = [
    {
      name: $t('dashboard.users.table-actions-assign-role'),
      onAction: handleAssignRole,
      disabledCondition: (user: IUser) => user.id === $userStore!.id,
    },
    {
      name: 'Resend credentials',
      onAction: handleResendCredentials,
      disabledCondition: (user: IUser) => user.id === $userStore!.id,
    },
    {
      name: 'Delete',
      onAction: handleDeleteUser,
      disabledCondition: (user: IUser) =>
        user.id === $userStore!.id || user.sessions.length > 0,
    },
  ];

  const handleSearch = (event: CustomEvent<any>) => {
    const { searchBy, searchQuery } = event.detail;
    query.offset = 0;
    if (searchBy === 'name') {
      const words = searchQuery.split(' ');
      query.name = words[0];
      query.lastName = words.slice(1).join(' ');
      query.email = '';
    } else {
      query.email = searchQuery;
      query.name = '';
      query.lastName = '';
    }
  };

  $: query, count, fetchUsers();
</script>

<div class="flex h-full w-full flex-col">
  <div
    class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
  >
    <h1 class="mt-1 text-xl font-bold text-primary">
      {$t('dashboard.users.title')}
    </h1>
  </div>
  <div class="p-6">
    <div
      class="flex w-full flex-col gap-4 rounded-lg border border-light-grey bg-white p-8"
    >
      <div class="flex flex-row justify-between">
        <div>
          <SearchUserInput
            searchByOptions={['name', 'email']}
            isAsync
            on:search={handleSearch}
          />
        </div>
        <div>
          <Button
            testId="invite-users-button"
            on:click={handleInviteUsers}
            style="outline"
            ><PlusIcon /><span>{$t('dashboard.users.button')}</span></Button
          >
        </div>
      </div>
      {#if users.length > 0}
        <UserTable actions={actions} users={filteredUsers} />

        <Paginator
          total={count}
          query={query}
          onChange={(newQuery) => {
            query = { ...newQuery };
          }}
        />
      {:else if users.length === 0}
        <div
          class="flex h-80 w-full grow items-center justify-center text-dark-grey"
        >
          No users found
        </div>
      {:else}
        <div class="flex h-80 w-full grow items-center justify-center">
          <Loading loadingSize="h-32 w-32" />
        </div>
      {/if}
    </div>
  </div>
</div>
