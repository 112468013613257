<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { slide } from 'svelte/transition';

  import CircleInfo from '../../../assets/svg/CircleInfo.svelte';
  import LockIcon from '../../../assets/svg/LockIcon.svelte';
  import { hostSocketSession } from '../../../store/socketSession';
  import DropdownButton from '../dropdown/Dropdown.svelte';
  import type { IGameStatusMenu } from './IGameStatusMenu';
  import type { IPlayer } from './IPlayer';

  export let buttonTextSize = 'text-base';
  export let buttonStartBackgroundColor = 'bg-primary';
  export let buttonStartMessage = 'Start game';
  export let buttonFinishMessage = 'Finish game';
  export let menuOption: string;
  export let gameStatusMenu: IGameStatusMenu;
  export let hasAdminPrivileges: boolean;
  export let players: IPlayer[] = [];
  export let lobbyAdmin: IPlayer;
  export let lastPhase: string;

  const dispatch = createEventDispatcher();

  const menuOptions: Record<string, IGameStatusMenu> = {
    isWaitingMenu: {
      isWaitingMenu: true,
      hasPhasesMenu: false,
      hasGameMenu: false,
    },
    hasPhasesMenu: {
      isWaitingMenu: false,
      hasPhasesMenu: true,
      hasGameMenu: false,
    },
    hasGameMenu: {
      isWaitingMenu: false,
      hasPhasesMenu: true,
      hasGameMenu: false,
    },
  };

  const sendStatusMenu = (menu: string) => {
    const selectedOption = menuOptions[menu] || {};
    gameStatusMenu = { ...gameStatusMenu, ...selectedOption };
    if (menu === 'hasGameMenu') {
      dispatch('finishGame', { gameStatusMenu, phase: lastPhase });
    } else {
      dispatch('statusMenu', { gameStatusMenu });
    }
  };
</script>

<div class="flex gap-2">
  {#if $hostSocketSession || hasAdminPrivileges}
    <DropdownButton
      mainButtonTextColor={'text-black'}
      mainButtonSize="w-full h-full"
      position={'isBottom'}
      title="Set player as admin"
      externalDivStyleClass={`bg-white/80 rounded-md ${buttonTextSize} font-medium transition ease-in duration-150 hover:scale-105 z-10`}
    >
      <div slot="dropdown-icon">
        <LockIcon />
      </div>

      <div slot="dropdown-items">
        {#each players as player}
          <button
            title={player.name}
            class={'block h-full w-[7rem] px-3 py-2 text-center text-sm font-semibold text-gray-700 hover:bg-gray-100'}
            on:click|stopPropagation={() =>
              dispatch('setAdmin', { playerId: player.id })}
            transition:slide|local
          >
            <div class="flex flex-row items-center gap-1">
              <span>{player.name}</span>
              {#if player.id === lobbyAdmin.id}
                <span class="ml-auto text-right">
                  <svg
                    id="admin-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H392.6c-5.4-9.4-8.6-20.3-8.6-32V352c0-2.1 .1-4.2 .3-6.3c-31-26-71-41.7-114.6-41.7H178.3zM528 240c17.7 0 32 14.3 32 32v48H496V272c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32V272c0-44.2-35.8-80-80-80s-80 35.8-80 80z"
                    />
                  </svg>
                </span>
              {/if}
            </div>
          </button>
        {/each}
      </div>
    </DropdownButton>

    {#if gameStatusMenu.isWaitingMenu}
      <button
        id="main-button"
        class={`text-white ${buttonStartBackgroundColor} rounded-md px-4 py-3 shadow-md ${buttonTextSize} font-medium transition duration-150 ease-in hover:scale-105`}
        on:click={() => sendStatusMenu(menuOption)}
      >
        {buttonStartMessage}
      </button>
    {/if}

    {#if gameStatusMenu.hasGameMenu}
      <button
        id="finish-button"
        title="Finish game"
        class={`text-white ${buttonStartBackgroundColor} rounded-md px-4 py-3 shadow-md ${buttonTextSize} font-medium transition duration-150 ease-in hover:scale-105`}
        on:click={() => sendStatusMenu(menuOption)}
      >
        {buttonFinishMessage}
      </button>
    {/if}

    {#if gameStatusMenu.hasPhasesMenu || gameStatusMenu.hasGameMenu}
      <button
        id="info-button"
        title="Info button"
        class={`rounded-md bg-white/80 px-4 py-3 text-black shadow-md ${buttonTextSize} font-medium transition duration-150 ease-in hover:scale-105`}
        on:click|stopPropagation={() => dispatch('open')}
      >
        <CircleInfo />
      </button>
    {/if}

    {#if gameStatusMenu.hasPhasesMenu}
      <button
        id="back-button"
        class={`rounded-md bg-black/80 px-4 py-3 text-white shadow-md ${buttonTextSize} font-medium transition duration-150 ease-in hover:scale-105`}
        on:click={() => sendStatusMenu('isWaitingMenu')}
      >
        Go Back
      </button>
    {/if}
  {:else if gameStatusMenu.hasPhasesMenu || gameStatusMenu.hasGameMenu}
    <button
      id="info-button"
      title="Info button"
      class={`rounded-md bg-white/80 px-4 py-3 text-black shadow-md ${buttonTextSize} font-medium transition duration-150 ease-in hover:scale-105`}
      on:click|stopPropagation={() => dispatch('open')}
    >
      <CircleInfo />
    </button>
  {/if}
</div>
