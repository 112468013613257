<script lang="ts">
  import Button from '@/lib/components/button/Button.svelte';
  import { t } from '@/locales/i18n';
</script>

<div
  class="flex h-fit w-[20rem] flex-col gap-4 rounded-md border border-light-grey p-6"
>
  <h4 class="font-bogart text-base font-extrabold text-primary">
    {$t('dashboard.experiences.start-session-section-title')}
  </h4>
  <p class="text-xs text-grey">
    {$t('dashboard.experiences.start-session-section-subtitle')}
  </p>
  <Button testId="start-session-button" rounded="rounded-md" on:click>
    <span> {$t('dashboard.experiences.start-session-section-button')}</span>
  </Button>
</div>
