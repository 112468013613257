<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import { hostSocketSession } from '../../../store/socketSession';
  import type { IGamePhase } from './IGamePhases';

  export let gamePhases: IGamePhase[];
  export let hasAdminPrivileges = false;

  const dispatch = createEventDispatcher();

  const sendStatusGamePhases = (phaseName: string, isCompleted: boolean) => {
    const updatedGamePhases = gamePhases.map((phase) => {
      if (phase.name === phaseName) {
        return {
          ...phase,
          gameInProgress: true,
        };
      }
      return phase;
    });

    dispatch('statusPhasesGames', {
      gamePhases: updatedGamePhases,
      hasGameMenu: isCompleted,
      phase: phaseName,
    });
  };
</script>

<div
  class="flex flex-row flex-wrap items-center justify-center gap-2 text-center"
>
  {#each gamePhases as phase}
    <button
      title={phase.name}
      on:click={() => {
        sendStatusGamePhases(phase.name, true);
      }}
      class="flex h-[8rem] w-[12rem] items-center justify-center rounded-md bg-accent/80 active:bg-accent xl:h-[12rem] xl:w-[16rem]"
      disabled={$hostSocketSession || hasAdminPrivileges ? false : true}
    >
      <span class="text-2xl font-bold capitalize text-black">{phase.name}</span>
    </button>
  {/each}
</div>
