<script>
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import UserIcon from '../icons/UserIcon.svelte';
</script>

<div class="flex w-fit flex-row items-center justify-center gap-2">
  <UserIcon style="text-grey w-12 h-12" />
  <div class="flex w-fit flex-col">
    <span class="font-bold text-grey">{$t('dashboard.sidebar.welcome')}</span>
    <span class="text-sm font-bold"
      >{$userStore?.name} {$userStore?.lastName}</span
    >
  </div>
</div>
