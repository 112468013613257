<script lang="ts">
  import type { ISession } from '@/interface/ISession';

  import SessionUserCard from '../../cards/SessionUserCard.svelte';
  import { closeModal } from '../modal.store';

  export let session: ISession;

  $: gridCols = session.users.length > 3 ? '3' : `${session.users.length}`;
</script>

<div
  on:keypress
  on:click={() => closeModal()}
  class="session-users-modal grid max-h-screen gap-4 overflow-auto rounded-lg"
  style={`--grid-cols: ${gridCols};`}
>
  {#each session.users as sessionUser}
    <div on:keypress on:click|stopPropagation>
      <SessionUserCard sessionUser={sessionUser} />
    </div>
  {/each}
</div>

<style>
  .session-users-modal {
    grid-template-columns: repeat(var(--grid-cols), minmax(0, 1fr));
  }
</style>
