<script lang="ts">
  export let qrMessage = 'Invite other players using this QR:';
  export let pin: string | undefined = '';
  export let hasMessage = false;
</script>

<div
  class="flex items-center justify-center rounded-md border-2 border-gray-200 bg-slate-50 p-2"
>
  <slot name="top">
    <div class="flex flex-col">
      <h3 class="text-center text-2xl font-bold">{qrMessage}</h3>
      {#if pin}
        <span class="text-center text-base">Join with pin: {pin} </span>
      {/if}
      {#if hasMessage}
        <slot name="message" />
      {/if}
    </div>
  </slot>
</div>
<slot name="qr-code" />
