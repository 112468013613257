<script lang="ts">
  export let text = 'menu button';
  export let selected = false;

  $: selectedStyles = selected
    ? 'text-primary border-r-4 border-r-primary'
    : 'text-dark-grey';
</script>

<button
  class={`${selectedStyles} flex w-full flex-row items-center gap-5 px-10 py-2 font-semibold transition duration-200 ease-in hover:text-primary`}
  on:click
>
  <slot />

  <span>{text}</span>
</button>
