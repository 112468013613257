<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import CloseXMark from '../../../assets/svg/CircleXMark.svelte';
  import clickOutside from '../helpers/clickOutside';

  export let showModal = false;
  export let backgroundColor = 'bg-white';
  export let backgroundBorderColor = 'border-gray-200';
  export let buttonTextColor = 'text-black';
  export let buttonTextSize = 'text-md';
  export let description = 'Modal description';
  export let title = 'Modal title';
  const dispatch = createEventDispatcher();
</script>

{#if showModal}
  <div
    class="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden p-2"
  >
    <div class="relative mx-auto my-6 w-auto max-w-xl">
      <slot name="modal-content">
        <div
          class={`relative flex w-full flex-col rounded-lg shadow-lg ${backgroundColor}`}
          use:clickOutside={() => dispatch('close')}
        >
          <div
            class={`flex items-start justify-between border-b p-4 ${backgroundBorderColor} rounded-t`}
          >
            <h3 id="modal-title" class="text-2xl font-semibold capitalize">
              {title}
            </h3>
            <button
              title="close modal"
              class="ml-auto p-1 transition duration-100 ease-in hover:scale-125"
              on:click={() => dispatch('close')}
            >
              <CloseXMark color="text-black" size="28" />
            </button>
          </div>
          <div class="relative my-2 p-4">
            <slot name="main-content">
              <p id="modal-description" class="text-lg leading-relaxed">
                {description}
              </p>
            </slot>
          </div>
          <div
            class={`flex items-center justify-end border-t p-4 ${backgroundBorderColor} rounded-b`}
          >
            <button
              class={`${buttonTextColor} font-bold uppercase ${buttonTextSize} transition duration-100 ease-in hover:scale-110`}
              type="button"
              on:click={() => dispatch('close')}
            >
              Close
            </button>
          </div>
        </div>
      </slot>
    </div>
  </div>
  <div class="fixed inset-0 z-40 bg-black opacity-25" />
{/if}
