<script lang="ts">
  import type { IPlayer } from 'src/lib/components/lobby/IPlayer';
  import type { SvelteComponent } from 'svelte';
  import { useNavigate } from 'svelte-navigator';

  import backgroundImageOne from '../../../src/assets/lobby-background.webp';
  import LobbyBottom from '../../../src/lib/components/lobby/LobbyBottom.svelte';
  import LobbyGamePhases from '../../../src/lib/components/lobby/LobbyGamePhases.svelte';
  import LobbyTop from '../../../src/lib/components/lobby/LobbyTop.svelte';
  import Modal from '../../../src/lib/components/modal/OLDModal.svelte';
  import QrCodeGenerator from '../../../src/lib/components/qr-code/QRCodeGenerator.svelte';
  import { ToastNotification } from '../../../src/lib/services/toast';
  import { getGameMap } from '../../lib/components/games/game.map';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import type { IGamePhase } from '../../lib/components/lobby/IGamePhases';
  import type { IGameStatusMenu } from '../../lib/components/lobby/IGameStatusMenu';
  import LobbyGameMenu from '../../lib/components/lobby/LobbyGameMenu.svelte';
  import LobbyWaitingPlayers from '../../lib/components/lobby/LobbyWaitingPlayers.svelte';
  import { openModal } from '../../lib/components/modal/modal.store';
  import Page from '../../lib/components/page/Page.svelte';
  import { ClientEvents } from '../../lib/services/together/events/client.events';
  import { ServerEvents } from '../../lib/services/together/events/server.events';
  import {
    endGame,
    setAdmin,
    startGame,
  } from '../../lib/services/together/together.socket.api';
  import { lobbyStatusStore } from '../../store/lobbyStore';
  import {
    guestSocketSession,
    hostSocketSession,
  } from '../../store/socketSession';
  import type { IBoxStatus } from '../my-boxes/IBoxStatus';
  import type { IPhase } from '../my-boxes/IPhase';
  import EndGameMessage from './EndGameMessage.svelte';
  import type { IExceptionEventError } from './IExceptionEventError';
  import type { IGameProps } from './IGameProps';
  import {
    onClientNotification,
    onGameEvent,
    onLobbyNotification,
    onLobbyShutdown,
    onLobbyStatus,
    onMenuStatus,
  } from './LobbyEvents';

  export let pin: string | undefined;

  const navigate = useNavigate();

  let players: IPlayer[] = [];

  let box: IBoxStatus = $lobbyStatusStore.box;
  let baseUrl = window.location.origin;
  let gameStatusMenu: IGameStatusMenu = {
    isWaitingMenu: true,
    hasPhasesMenu: false,
    hasGameMenu: false,
  };
  const buildPhaseMenu = (phases: IPhase[]) => {
    phases.sort((a: IPhase, b: IPhase) => a.order - b.order);
    const menu = box.phases.map((phase: IPhase) => {
      const phaseMenuItem = {
        name: phase.name,
        isCompleted: false,
        gameComponentName: phase.gameConfig.gameName,
        gameInProgress: false,
      };
      return phaseMenuItem;
    });
    return menu;
  };
  let phaseMenu: IGamePhase[] = box?.phases ? buildPhaseMenu(box.phases) : [];

  let gameProps: IGameProps = {
    gameStatus: null,
  };

  let client = $hostSocketSession || $guestSocketSession;
  let isModalOpen = true;
  let hasAdminPrivileges = false;
  let lobbyAdmin: IPlayer;
  let lastPhase: string;

  let gameComponent: typeof SvelteComponent;
  const loadGameComponent = async (gameName: string) => {
    const gameMap = getGameMap();
    const gameFileName = gameMap.get(gameName);

    let importedModule = await import(
      `../../lib/components/games/${gameFileName}.svelte`
    );
    gameComponent = importedModule.default;
  };

  const handleGamePhasesStatus = async (event: CustomEvent<any>) => {
    if (players.length < 2) {
      ToastNotification.error(
        'You must have at least 2 players to start the game',
      );
      return;
    }
    const currentPhase = box.phases.find((phase: IPhase) => {
      return phase.name === event.detail.phase;
    });

    try {
      const message = await startGame(
        client!,
        currentPhase?.gameConfig.gameName as string,
      );
      ToastNotification.success(message);

      lastPhase = event.detail.phase;

      phaseMenu = event.detail.gamePhases;
      gameStatusMenu.hasGameMenu = event.detail.hasGameMenu;
      gameStatusMenu.hasPhasesMenu = false;
      gameStatusMenu.isWaitingMenu = false;

      sendMenuStatus();
    } catch (error) {
      const eventError = error as IExceptionEventError;
      ToastNotification.error(eventError.message);
    }
  };

  const handleMenuStatus = (event: CustomEvent<any>) => {
    gameStatusMenu = event.detail.gameStatusMenu;

    phaseMenu.forEach((itemMenu) => {
      itemMenu.gameInProgress = false;
    });

    sendMenuStatus();
  };

  const handleToggleModal = () => {
    isModalOpen = !isModalOpen;
  };

  const onEndGameResponse = (gameEvent: any) => {
    if (gameEvent.event === ServerEvents.endGameResponse) {
      phaseMenu.forEach((itemMenu: IGamePhase) => {
        if (itemMenu.name === lastPhase) {
          itemMenu!.gameInProgress = false;
          itemMenu!.isCompleted = false;
        }
      });
      gameStatusMenu.hasGameMenu = false;
      gameStatusMenu.hasPhasesMenu = true;
    }
  };

  const onLobbyUpdate = (lobby: any) => {
    checkAdminPrivileges(lobby.lobbyAdmin);
    lobbyAdmin = lobby.lobbyAdmin ? lobby.lobbyAdmin : {};
    box = lobby.box;
    gameProps.gameStatus = lobby.currentGame;
    players = lobby.players;
    if (phaseMenu.length === 0) {
      phaseMenu = buildPhaseMenu(box.phases);
    }
  };

  const onAllGamesFinished = () => {
    openModal<EndGameMessage>(EndGameMessage, { allowCloseClick: false });
  };

  let allGameFinished = false;
  const updateMenu = (menu: any) => {
    gameStatusMenu = menu.gameStatusMenu;
    phaseMenu = menu.gamePhases;
    lastPhase = menu.lastPhase;

    if (
      phaseMenu.every(
        (itemMenu: IGamePhase) =>
          itemMenu.isCompleted === true && !allGameFinished,
      )
    ) {
      allGameFinished = true;
      onAllGamesFinished();
    }
  };

  const onLobbyShutdownNavigate = () => {
    navigate('/join-lobby');
  };

  onGameEvent(onEndGameResponse);

  onLobbyStatus(onLobbyUpdate);

  onLobbyNotification((message: string) => {
    ToastNotification.normal(message);
  });

  onClientNotification((message: string) => {
    ToastNotification.normal(message);
  });

  onMenuStatus(updateMenu);

  onLobbyShutdown(onLobbyShutdownNavigate);

  const sendMenuStatus = () => {
    client?.emit(ClientEvents.menuStatus, {
      menu: { gameStatusMenu, gamePhases: phaseMenu, lastPhase },
      pin,
    });
  };

  const handleGameEvent = (event: CustomEvent<any>) => {
    if (event.detail.phase) {
      phaseMenu.forEach((itemMenu: IGamePhase) => {
        if (itemMenu.name === event.detail.phase) {
          itemMenu!.gameInProgress = false;
          itemMenu!.isCompleted = true;
        }
      });
      gameStatusMenu.hasGameMenu = false;
      gameStatusMenu.hasPhasesMenu = true;
    }

    sendMenuStatus();
  };

  const handleFinishGame = async (event: CustomEvent<any>) => {
    try {
      await endGame(client!);

      phaseMenu.forEach((itemMenu: IGamePhase) => {
        if (itemMenu.name === event.detail.phase) {
          itemMenu.gameInProgress = false;
          itemMenu.isCompleted = true;
        }
      });

      if (event.detail.gameStatusMenu) handleMenuStatus(event);

      sendMenuStatus();

      ToastNotification.normal('The game has ended');
    } catch (error) {
      const eventError = error as IExceptionEventError;
      ToastNotification.error(eventError.message);
    }
  };

  const checkAdminPrivileges = (lobbyAdmin: IPlayer) => {
    if (client?.id === lobbyAdmin?.id) {
      hasAdminPrivileges = true;
    } else {
      hasAdminPrivileges = false;
    }
  };

  const handleSetAdmin = (event: CustomEvent<any>) => {
    try {
      setAdmin(client!, event.detail.playerId);
    } catch (error) {
      const eventError = error as IExceptionEventError;
      ToastNotification.error(eventError.message);
    }
  };

  $: {
    players = $lobbyStatusStore.players;
  }
</script>

<Page>
  <div class="relative h-full w-full grow">
    <img
      class="absolute h-full w-full object-cover"
      src={backgroundImageOne}
      alt="Drawing of a bedroom with chair and desk in cartoon style"
    />
    <div class="absolute inset-0 bg-gray-900 bg-opacity-10" />
    <div class="relative flex min-h-full flex-col gap-1">
      <div class="mt-2 flex flex-wrap justify-center gap-2 p-2">
        {#if gameStatusMenu.isWaitingMenu}
          <LobbyTop pin={pin}>
            <div
              slot="qr-code"
              class="flex justify-center border-2 border-gray-200 bg-slate-50 p-2"
            >
              <QrCodeGenerator
                textCode={`${baseUrl}/join-lobby/${pin}`}
                squareSize={80}
                marginQR={0}
                whiteColorQR={'#F8FAFC'}
              />
            </div>
          </LobbyTop>
        {:else}
          <LobbyTop qrMessage={box.title} hasMessage={true}>
            <span slot="message" class="text-center text-base">
              Why not try this
              <a
                class="font-bold text-blue-600"
                href="https://sptfy.com/OEPE"
                target="_blank"
              >
                playlist?
              </a>
              &rarr;
            </span>
            <div
              slot="qr-code"
              class="flex justify-center border-2 border-gray-200 bg-slate-50 p-2"
            >
              <QrCodeGenerator
                textCode="https://sptfy.com/OEPE"
                squareSize={80}
                marginQR={0}
                whiteColorQR={'#F8FAFC'}
              />
            </div>
          </LobbyTop>
        {/if}
      </div>
      <div
        class="flex basis-full flex-wrap justify-center p-1 md:flex-col md:items-end md:justify-end md:p-3 md:pr-10"
      >
        <LobbyGameMenu
          on:statusMenu={handleMenuStatus}
          on:open={() => handleToggleModal()}
          on:finishGame={handleFinishGame}
          on:setAdmin={handleSetAdmin}
          gameStatusMenu={gameStatusMenu}
          players={players}
          lastPhase={lastPhase}
          menuOption={gameStatusMenu.isWaitingMenu
            ? 'hasPhasesMenu'
            : 'hasGameMenu'}
          buttonStartMessage="Start game"
          buttonFinishMessage="Finish game"
          hasAdminPrivileges={hasAdminPrivileges}
          lobbyAdmin={lobbyAdmin}
        />
      </div>
      <div class="flex min-h-[50vh] flex-wrap justify-center lg:min-h-[60vh]">
        {#if gameStatusMenu.isWaitingMenu}
          <LobbyWaitingPlayers players={players} />
        {/if}

        {#if gameStatusMenu.hasPhasesMenu && phaseMenu.length > 0}
          <LobbyGamePhases
            on:statusPhasesGames={handleGamePhasesStatus}
            gamePhases={phaseMenu}
            hasAdminPrivileges={hasAdminPrivileges}
          />
          <Modal
            title={`Game phases`}
            description={box.description}
            showModal={isModalOpen}
            on:close={() => handleToggleModal()}
          />
        {/if}
        {#if gameStatusMenu.hasGameMenu}
          {#each phaseMenu as phase}
            {#if phase.gameInProgress}
              {#await loadGameComponent(phase.gameComponentName)}
                <Loading
                  externalStyles="flex flex-row justify-center items-center"
                  loadingSize="h-32 w-32"
                  loadingColor="text-accent"
                  loadingSpinnerOpacity="opacity-100"
                />
              {:then}
                <svelte:component
                  this={gameComponent}
                  gameProps={gameProps}
                  on:gameEvent={handleGameEvent}
                  on:finishGame={handleFinishGame}
                  gamePhase={phase.name}
                  hasManyPlayers={players.length > 2}
                  hasAdminPrivileges={hasAdminPrivileges}
                />
                <Modal
                  title={`Game ${gameProps.gameStatus?.name}`}
                  description={`${gameProps.gameStatus?.description}`}
                  showModal={isModalOpen}
                  on:close={() => handleToggleModal()}
                />
              {/await}
            {/if}
          {/each}
        {/if}
      </div>
      <div class="mt-auto flex justify-end gap-1 p-2 md:p-4 md:pr-10">
        <LobbyBottom players={players} />
      </div>
    </div>
  </div>
</Page>
