<script lang="ts">
  import moment from 'moment';
  import type { ISessionUser } from '@/interface/ISessionUser';

  import UserIcon from '../icons/UserIcon.svelte';

  export let sessionUser: ISessionUser;
</script>

<div
  data-cy="feedback-card"
  class="grid h-36 w-72 shrink-0 grid-cols-5 overflow-hidden rounded-lg border border-light-grey p-4"
>
  <div class="h-full w-fit grid-cols-1 items-center">
    <UserIcon style="text-grey w-9 h-9" />
  </div>
  <div class="col-span-4 flex w-full flex-col gap-2">
    <div class="flex flex-row justify-between">
      <span class="text-sm font-bold text-dark-grey"
        >{sessionUser.user.name} {sessionUser.user.lastName}</span
      >
      <span class="text-xs text-grey"
        >{moment(sessionUser.updatedAt).fromNow()}</span
      >
    </div>
    <div class="flex flex-row justify-between">
      <p class="text-xs text-grey">
        {sessionUser.feedbackComment}
      </p>
    </div>
  </div>
</div>
