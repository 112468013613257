<script>
  import { t } from '@/locales/i18n';
</script>

<div
  class="z-20 flex w-[27rem] flex-col items-center justify-center gap-6 rounded-lg p-14 xl:w-[35rem]"
>
  <p class="text-center text-2xl text-dark-grey">
    {@html $t('signup.info-text-1')}
  </p>
  <p class="text-center text-2xl text-dark-grey">
    {@html $t('signup.info-text-2')}
  </p>
</div>
