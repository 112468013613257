export default function clickOutside(node: Node, callBackFunction: () => void) {
  const handleClick = (event: MouseEvent) => {
    if (
      node &&
      !node.contains(event.target as Node) &&
      !event.defaultPrevented
    ) {
      callBackFunction();
    }
  };

  document.addEventListener('click', handleClick, true);

  return {
    update(newCallbackFunction: () => void) {
      callBackFunction = newCallbackFunction;
    },
    destroy() {
      document.removeEventListener('click', handleClick, true);
    },
  };
}
