<script lang="ts">
  import { closeModal, modals } from './modal.store';

  let currentModals = $modals;
  $: {
    currentModals = $modals;
  }

  const handleClickModal = () => {
    closeModal();
  };
</script>

{#each currentModals as modal, index}
  <div
    on:click={() => modal.options?.allowCloseClick && handleClickModal()}
    on:keydown
    style={`z-index: ${100 + index}`}
    class={`absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center ${modal.options?.bgStyles}`}
  >
    <div on:click|stopPropagation on:keydown>
      <svelte:component this={modal.component} {...modal.options?.props} />
    </div>
  </div>
{/each}
