<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import LandingPageInfoElement from '@/assets/svg/LandingPageInfoElement.svelte';
  import Rizz2 from '@/assets/svg/Rizz2.svelte';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import Button from '@/lib/components/button/Button.svelte';
  import NewLoginForm from '@/lib/components/login-form/LoginForm.svelte';
  import { t } from '@/locales/i18n';
</script>

<main class="flex h-screen w-full flex-row items-center justify-center">
  <section
    class="hidden h-full w-[90rem] flex-col items-center justify-center overflow-hidden bg-[#E0E3FF] pt-6 lg:flex"
  >
    <div class="flex h-60 w-full flex-col items-center justify-center gap-6">
      <TogetherLogo className="h-24" />
      <h1 class="text-3xl font-semibold text-dark-grey">
        Bring remote teams closer
      </h1>
    </div>

    <LandingPageInfoElement styles="h-[50rem] " />
  </section>
  <section
    class="relative flex h-full w-full flex-col items-center justify-center overflow-hidden"
  >
    <Rizz2 styles="absolute w-[80rem] -right-20 -z-10" />
    <NewLoginForm />
    <div class="z-20 mt-10">
      <Button on:click={() => navigate('/')} rounded="rounded-md"
        ><span>{@html $t('login.home-button')}</span></Button
      >
    </div>
  </section>
</main>
