<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import ChevronIcon from '@/assets/svg/ChevronIcon.svelte';
  import type { IExperience } from '@/interface/IExperience';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import StartSessionModal from '@/lib/components/modal/modals/StartSessionModal.svelte';
  import {
    getExperienceById,
    getExperienceFeedback,
  } from '@/lib/services/together/together.api';
  import UserFeedbackSection from '@/routes/dashboard/experiences/UserFeedbackSection.svelte';

  import ExperienceDetails from './ExperienceDetails.svelte';
  import StartSessionSection from './StartSessionSection.svelte';

  export let id: string;

  const handleStartSession = (experience: IExperience) => {
    openModal(StartSessionModal, {
      allowCloseClick: false,
      props: {
        experience,
      },
    });
  };
</script>

<div class="flex h-full w-full flex-col">
  {#await getExperienceById(Number(id))}
    <div class="flex h-full w-full grow flex-col items-center justify-center">
      <Loading loadingSize="h-32 w-32" />
    </div>
  {:then experience}
    <div
      class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
    >
      <div
        class="flex flex-row items-center gap-2 py-4 hover:cursor-pointer"
        on:keypress
        on:click={() => navigate(-1)}
      >
        <ChevronIcon style="rotate-180 fill-primary" />
        <h1 class="mt-1 text-xl font-bold text-primary">{experience.name}</h1>
      </div>
    </div>
    <div class="flex min-w-full flex-row gap-16 p-6">
      <div class="flex w-[40rem] flex-col gap-8">
        <ExperienceDetails experience={experience} />
        {#await getExperienceFeedback( Number(id), { limit: 6, offset: 0, minRating: 4 }, )}
          <Loading loadingSize="h-20 w-20" />
        {:then { sessionUsers }}
          <UserFeedbackSection sessionUsers={sessionUsers} />
        {/await}
      </div>
      <StartSessionSection on:click={() => handleStartSession(experience)} />
    </div>
  {/await}
</div>
