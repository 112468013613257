import type { ComponentProps, SvelteComponent } from 'svelte';
import { writable } from 'svelte/store';

interface IModal {
  component: typeof SvelteComponent;
  options?: {
    props?: Record<string, any>;
    onClose?: () => void;
    allowCloseClick?: boolean;
    bgStyles?: string;
  };
}

export const modals = writable<IModal[]>([]);

export const openModal = <T extends SvelteComponent>(
  component: new (...args: any[]) => T,
  options?: {
    props?: ComponentProps<T>;
    onClose?: () => void;
    allowCloseClick?: boolean;
    bgStyles?: string;
  },
) => {
  const defaultOptions = {
    props: {} as ComponentProps<T>,
    allowCloseClick: true,
    bgStyles: 'bg-black bg-opacity-10',
  };
  const mergedOptions = { ...defaultOptions, ...options };
  modals.update((modalArray) => [
    ...modalArray,
    { component, options: mergedOptions },
  ]);
};

export const closeModal = () => {
  modals.update((modalArray) => {
    modalArray[modalArray.length - 1]?.options?.onClose?.();
    return modalArray.slice(0, -1);
  });
};
