<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { t } from '@/locales/i18n';

  export let searchByOptions: string[] = [];
  export let isAsync = false;

  let searchBy: string;
  let searchQuery = '';
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const dispatch = createEventDispatcher();

  const handleSearch = () => {
    if (isAsync) {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch('search', { searchBy, searchQuery });
      }, 1500);
    } else {
      dispatch('search', { searchBy, searchQuery });
    }
  };
</script>

<div class="relative mb-2 mt-1 flex w-full items-center justify-start">
  <select
    class="mr-2 rounded border p-2 capitalize"
    data-cy={isAsync ? 'search-by-selector-async' : 'search-by-selector'}
    bind:value={searchBy}
  >
    {#each searchByOptions as option}
      <option
        value={option}
        class="capitalize"
        data-cy="search-by-option-{option}"
      >
        {option === 'name' ? $t('search.by-name') : $t('search.by-email')}
      </option>
    {/each}
  </select>
  <div class="relative w-full">
    <input
      type="text"
      placeholder={$t('search.placeholder')}
      class="w-full rounded-md p-2 pl-10 text-sm text-gray-700 ring-1 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600"
      data-cy={isAsync ? 'search-input-async' : 'search-input'}
      bind:value={searchQuery}
      on:input={handleSearch}
    />
    <svg
      class="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform text-gray-500"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  </div>
</div>
