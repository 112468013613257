import {
  CognitoUserPool,
  type CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { get, writable, type Writable } from 'svelte/store';

import { refreshUserToken } from '../lib/services/cognito';

const userPoolId = import.meta.env['VITE_COGNITO_USER_POOL_ID'];
const clientId = import.meta.env['VITE_COGNITO_APP_CLIENT_ID'];
const endpoint = import.meta.env['VITE_COGNITO_ENDPOINT'];

const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
  endpoint,
});

export const userPoolStore = writable(userPool);

type UserSessionStore = Writable<CognitoUserSession | null>;

const initUserSession = async (): Promise<CognitoUserSession | null> => {
  const userPool = get(userPoolStore);

  const user = userPool.getCurrentUser();
  if (!user) return null;

  try {
    const userSession: CognitoUserSession = await new Promise(
      (resolve, reject) => {
        user.getSession((_: Error, session: CognitoUserSession | null) => {
          if (!session) reject("User session doesn't exist");
          else resolve(session);
        });
      },
    );

    if (userSession.isValid()) {
      return userSession;
    } else {
      return await refreshUserToken(get(userPoolStore));
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const userSessionStore: UserSessionStore = writable(
  await initUserSession(),
);
